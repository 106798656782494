/* Component - Jumbotron */

.jumbotron {
    background: @silver;
    border-radius: 6px;
}
.jumbotron h1, 
.jumbotron .h1 {
    font-size: 56px;
}
.jumbotron p {
    font-size: 18px;
}