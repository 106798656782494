/* Option - Page with Two Sidebar */

.page-with-two-sidebar.page-sidebar-fixed .sidebar.sidebar-right {
    position: fixed;
}
.page-with-two-sidebar .sidebar.sidebar-right,
.page-with-two-sidebar .sidebar-bg.sidebar-right {
    right: 0;
    left: auto;
}
.page-with-two-sidebar .content {
    margin-right: 220px;
}
.page-with-two-sidebar.page-sidebar-minified .sidebar.sidebar-right,
.page-with-two-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right {
    width: 220px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar.sidebar-right,
.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar-bg.sidebar-right {
    right: -220px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .content {
    margin-right: 0;
}
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .sidebar-bg.sidebar-right, 
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .sidebar.sidebar-right {
    right: -250px;
}
.page-with-two-sidebar.page-right-sidebar-collapsed .content, 
.page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .content {
    margin-right: 0;
}
