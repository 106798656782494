/* Component - Badge & Label Setting */

.badge {
    font-size: 75%;
    line-height: 1.25;
    font-weight: 600;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}
.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: 600;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
}
.badge.badge-square { 
	border-radius: 0; 
}
.badge.badge-default, 
.label.label-default { background: #f2f3f4; color: @black; }
.badge.badge-danger, 
.label.label-danger { background: @red; }
.badge.badge-warning, 
.label.label-warning { background: @orange; }
.badge.badge-success, 
.label.label-success { background: @green; }
.badge.badge-info, 
.label.label-info { background: @aqua; }
.badge.badge-primary, 
.label.label-primary { background: @blue; }
.badge.badge-secondary,
.label.label-secondary { background: @grey; }
.badge.badge-lime,
.label.label-lime { background: @lime; }
.badge.badge-yellow,
.label.label-yellow { background: @yellow; color: #000; }
.badge.badge-purple,
.label.label-purple { background: @purple; }
.badge.badge-light,
.label.label-light { background: #fff; color: #62686d; }
.badge.badge-inverse, 
.label.label-inverse,
.badge.badge-dark, 
.label.label-dark { background: @black; }
