@media (min-width: 768px) {

  .navbar-header {
    float: none;
  }

  div.dataTables_wrapper div.dataTables_paginate {
    .paginate_button {
      &.first, &.last {
        display: none;
      }
    }

  }
}

@media (max-width: 767px) {

  .navbar-header {
    border-bottom: none;
  }

  .navbar-inverse {
    .navbar-header {
      background: none;
    }
  }

  .navbar-brand {
    width: 220px;
  }

  .navbar-toggle {
    float: left;
  }

  .page-header-fixed {
    padding-top: 54px;
  }

  div.dataTables_wrapper div.dataTables_paginate {
    margin: 10px 0 !important;
    text-align: center;

    .paginate_button {
      display: none;
      &.first, &.previous, &.next, &.last, &.active {
        display: inline;
        a {
          min-width: 48px;
        }
      }
    }

  }

  .table {

    .doc-send-num-col, .doc-receive-num-col {
      width: auto;
    }

    .doc-receive-num-col:before {
      content: "เลขรับ ";
      font-weight: normal;
      color: @grey_darker;
    }

    .doc-send-num-col:before {
      content: "เลขส่ง ";
      font-weight: normal;
      color: @grey_darker;
    }

    .doc-from-col {
      width: auto;
      max-width: 100%;
      min-height: 60px;
    }

    .doc-title-col {
      padding-top: 0;
    }

    .doc-attach-col {
      position: absolute;
      right: 30px;
      margin-top: -50px;
    }
    
    .doc-date-col {
      float: right;
      width: auto;
      
      p {
        display: inline-block;
      }

      span {
        display: none;
      }
    }

    &#received_table {
      .doc-date-col {
        float: right;
        width: auto;

        p {
          display: none;
        }

        span {
          display: inline-block;
        }
      }
    }
  }

}