@import "../color-admin-theme/less/styles";
@import "_components";
@import "_content";
@import "_helper";
@import "_pages";
@import "_plugins";
@import "_responsive";
@import "_variable";



.form-control {
  font-size: 14px;
}

label {
  > .form-control {
    font-weight: normal;
  }
}

.footer-without-sidebar {
  margin: auto;
  text-align: center;
}