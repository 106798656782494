//Page Loader Spinner

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner, .spinner-small {
  border-top: 2px solid @blue;
}

.pace-top {
  .pace {
    top: 0;
    .pace-progress {
      top: 0;
    }
  }
}

//PACE
.pace {

  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
  z-index: 1020;

  &.pace-inactive {
    display: block;
    opacity: 0;
    z-index: 0;
  }

  .pace-activity {
    //display: block;
    //position: fixed;
    //width: 14px;
    //height: 14px;
    //border: solid 2px transparent;
    //border-top-color: @blue;
    //border-left-color: @blue;
    //border-radius: 10px;
    //-webkit-animation: pace-spinner 400ms linear infinite;
    //-moz-animation: pace-spinner 400ms linear infinite;
    //-ms-animation: pace-spinner 400ms linear infinite;
    //-o-animation: pace-spinner 400ms linear infinite;
    //animation: pace-spinner 400ms linear infinite;
    //top: 58px;
    //right: 2px;
    //z-index: 1020;
    display: none;
  }

  &:before {
    top: 54px;
  }

  .pace-progress {
    background: @blue;
    position: fixed;
    right: 100%;
    width: 100%;
    height: 2px;
    top: 54px;
    z-index: 1020;
    -webkit-transition: width .5s;
    -moz-transition: width .5s;
    -ms-transition: width .5s;
    -o-transition: width .5s;
    transition: width .5s;
  }

  .pace-progress-inner {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    opacity: 1.0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

#page-loader {
  z-index: 9999;
}