/* Plugin - Iris Range Slider */

.irs-line-left,
.irs-bar-edge {
    border-radius: 6px 0 0 6px !important;
}
.irs-line-right {
    border-radius: 0 6px 6px 0 !important;
}
.irs-to,
.irs-from, 
.irs-single {
    background: @black !important;
}
.irs-bar-edge {
	background: #b9c0c3 !important;
    border-right: 1px solid #fff;
    top: 27px !important;
    height: 4px !important;
}
.irs-line-mid,
.irs-line-left,
.irs-line-right {
    background: #f2f3f4 !important;
}
.irs-bar {
	background: @grey_darker !important;
}
.irs-bar,
.irs-line {
    height: 4px !important;
    top: 27px !important;
}
.irs-min, .irs-max {
    background: #e9edef !important;
    color: #5b6165 !important;
}
.irs-slider.to,
.irs-slider.from,
.irs-slider.single {
	background: @grey_darker;
    box-shadow: 0 2px 6px rgba(0,0,0,0.25);
    border-radius: 16px;
    border: 6px solid #fff;
    cursor: pointer;
}