/* Plugin - Powerange Slider (Added in V1.2) */

.slider-wrapper {
    padding: 15px 30px !important;
}
.slider-wrapper.slider-without-range {
    padding: 15px 0 !important;
}
.slider-wrapper.slider-vertical {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.range-min, 
.range-max {
    color: @black_darker !important;
}
.range-handle {
    cursor: pointer !important;
}
.range-quantity { background: @green !important; }
.red .range-quantity { background: @red !important; }
.blue .range-quantity { background: @blue !important; }
.purple .range-quantity { background: @purple !important; }
.orange .range-quantity { background: @orange !important; }
.black .range-quantity { background: @black !important; }