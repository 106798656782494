a {
  color: @main_color;
  &:focus, &:hover {
    color: @dark_main_color;
  }
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  &.content-full-width {
    .page-header {
      margin: 0 0 20px;
    }
  }
}

.badge.badge-silver,
.label.label-silver {
  background: @silver;
  color: #525252;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .1);
  -moz-box-shadow: inset 0 0 1px rgba(0, 0, 0, .1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, .1);
}

.attached-document {
  list-style-type: none;
  margin: 15px 0 0;
  padding: 0;

  & > li {
    width: 130px;
    float: left;
    background: white;
    color: #707478;
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    border: solid 1px #d9e0e7;
    .transition(all 200ms);

    &:before {
      display: none;
    }

    &:hover {
      .box-shadow(0 8px 8px rgba(0, 0, 0, .2));
    }

    & img {
      height: 100%;
      min-width: 128px;
      max-width: none;
      object-fit: cover;
    }
    & .document-name {
      position: relative;
      margin-top: -30px;
      padding: 5px 10px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #2d353c;
      text-decoration: none;
      background: #fafafa;

    }
    & .document-file {
      height: 181px;
      background: none;
      overflow: hidden;
      text-align: center;
      line-height: 70px;
      font-size: 32px;
      margin: 0;
    }

    & .document-type {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }

    .btn-delete-file-attach {
      position: absolute;
      top: 0;
      right: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border-bottom-left-radius: 4px;

    }
  }
}

.attached-document, .file-icon {
  .fa-file-image, .fa-file-jpg, .fa-file-jpeg, .fa-file-png, .fa-file-tif {
    &:before {
      content: "\f1c5";
      color: @aqua;
    }
  }

  .fa-file-pdf {
    &:before {
      color: @red;
    }
  }
  .fa-file-word, .fa-file-doc, .fa-file-docx, .fa-file-odt, .fa-file-rtf {
    &:before {
      content: "\f1c2";
      color: @blue;
    }
  }
  .fa-file-excel, .fa-file-xls, .fa-file-xlsx, .fa-file-csv, .fa-file-ods {
    &:before {
      content: "\f1c3";
      color: @green;
    }
  }
  .fa-file-powerpoint, .fa-file-ppt, .fa-file-pptx, .fa-file-odp {
    &:before {
      content: "\f1c4";
      color: @orange;
    }
  }
  .fa-file-zip, .fa-file-rar, .fa-file-7z {
    &:before {
      content: "\f1c6";
      color: @yellow;
    }
  }
}