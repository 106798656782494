.note-editor {
  &.note-frame {
    border: 1px solid @form_border_color !important;
  }
}

.note-toolbar {
  &.panel-heading {
    .btn-group {
      .btn.btn-sm {
        margin-top: 0;
      }
    }
  }
}
