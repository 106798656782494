/* Page Content */

.content {
    margin-left: 220px;
    padding: 20px 30px;
}
.content.content-full-width {
    padding: 0;
}
.content.content-full-width .page-header {
    margin: 20px;
}
.content.content-full-width .breadcrumb {
    margin: 0 20px 0 0;
}
.content.content-inverse-mode h1,
.content.content-inverse-mode h2,
.content.content-inverse-mode h3,
.content.content-inverse-mode h4,
.content.content-inverse-mode h5,
.content.content-inverse-mode h6,
.content.content-inverse-mode .page-header,
.content.content-inverse-mode .breadcrumb a {
    color: #fff;
}
.content.content-inverse-mode .breadcrumb > li,
.content.content-inverse-mode .page-header small {
	color: rgba(255,255,255,0.75);
}
.content.content-full-width .page-header,
.content.content-full-width .breadcrumb {
    position: relative;
    z-index: 10;
}
.content.content-full-width .breadcrumb {
    z-index: 20;
}
.page-content-full-height .content {
    position: absolute;
    left: 0;
    top: 50px;
    right: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.page-header {
    font-size: 24px;
    margin: 0 0 15px;
    padding: 0;
    border: none;
    line-height: 32px;
    font-weight: 500;
}
.page-header small {
	line-height: 20px;
}
.breadcrumb {
    padding: 0;
    margin: 0;
    background: none;
}
.breadcrumb > li {
    line-height: 32px;
}
.ie8 .breadcrumb > li {
    display: inline;
}
.breadcrumb > li a {
    color: #333;
}
.breadcrumb-item + .breadcrumb-item::before {
	color: #ccc;
}
