.o-60 {
  opacity: .6;
}

.line-height-s {
  line-height: 0.5em;
}

.line-height-m {
  line-height: 1em;
}

.line-height-l {
  line-height: 1.5em;
}

.line-height-x {
  line-height: 2em;
}

.line-height-xl {
  line-height: 2.5em;
}

.cursor-pointer {
  cursor: pointer;
}

.show {
  opacity: 1;
}

.text-muted {
  color: #767676;
}

.width-full {
  width: 100% !important;
  max-width: 100% ;
}

@media (max-width: 767px) {
  .mobile-transparent {
    background-color: transparent;
  }
}
