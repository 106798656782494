/* Component - Alert */

.alert {
    border: none;
    padding: 15px;
}
.alert.alert-success { background: #b3e6e6; color: #025656; }
.alert.alert-info { background: #c8e9f3; color: #255b6c; }
.alert.alert-danger { background: #ffcdcc; color: #802d2b; }
.alert.alert-warning { background: #fce1ba; color: #7b4e0e; }
.alert.alert-primary { background: #c2ddf7; color: #1a4772; }
.alert.alert-dark { background: #c0c2c4; color: #5b6165; }
.alert.alert-secondary { background: #e9edef; color: #5b6165; }
.alert.alert-light { background: #fff; color: #62686d; }
.alert.alert-purple { background: #d4d7e9; color: #393e5b; }
.alert.alert-yellow { background: #fff4b2; color: #806d00; }
.alert.alert-lime { background: #ddefc9; color: #486626; }

.alert.alert-success .alert-link { color: #012b2b; }
.alert.alert-info .alert-link { color: #122e36; }
.alert.alert-danger .alert-link { color: #401716; }
.alert.alert-warning .alert-link { color: #3d2707; }
.alert.alert-primary .alert-link { color: #0d2439; }
.alert.alert-dark .alert-link { color: #2e3132; }
.alert.alert-secondary .alert-link { color: #2e3132; }
.alert.alert-light .alert-link { color: #2e353c; }
.alert.alert-purple .alert-link { color: #1d1f2e; }
.alert.alert-yellow .alert-link { color: #403600; }
.alert.alert-lime .alert-link { color: #243313; }
