/* Page Top Menu */

.page-with-top-menu {
    padding-top: 90px;
}
.page-with-top-menu .sidebar {
    padding-top: 90px;
}
.top-menu {
    background: @black;
    height: 40px;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 1015;
}
.top-menu .nav {
    white-space: nowrap;
    display: block;
}
.top-menu .nav > li {
    display: inline-block;
    position: relative;
}
.top-menu .nav > li + li {
    margin-left: -3px;
}
.top-menu .nav > li > a {
    padding: 10px 20px;
    color: #a8acb1;
    display: block;
    line-height: 20px;
    text-decoration: none;
}
.top-menu li > a .caret {
	display: block;
    float: right;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    border: none;
}
.top-menu li > a .caret:before {
    content: '\f107';
    display: block;
    text-align: center;
    transition: all .2s linear;
    opacity: 0.5;
}
.top-menu .nav > li > a .badge,
.top-menu .nav > li > a .label {
    margin-left: 5px;
}
.top-menu .nav > li > a .badge {
    background: #1b1f24;
}
.top-menu .nav > li > a .icon-img {
	float: left;
	margin-right: 7px;
	width: 20px;
	height: 20px;
	margin-left: -3px;
	overflow: hidden;
	border-radius: 4px;
}
.top-menu .nav > li > a .icon-img img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}
.top-menu .nav > li > a .badge.pull-right,
.top-menu .nav > li > a .label.pull-right {
    margin-top: 1px;
}
.top-menu .nav > li.active > a .label.label-theme,
.top-menu .nav > li.active > a .badge {
    background: rgba(0,0,0,0.4);
}
.top-menu .nav > li > a i {
    float: left;
    font-size: 14px;
    margin-right: 10px;
    line-height: 20px;
    width: 14px;
    text-align: center;
}
.top-menu .nav > li > a .caret.pull-right {
    margin-top: 8px;
    margin-left: 5px;
}
.top-menu .nav > li:hover > a,
.top-menu .nav > li:focus > a,
.top-menu .nav > li > a:hover,
.top-menu .nav > li > a:focus {
	background: none;
	color: #fff;
}

/* Page Top Menu - Sub Menu (Added in V1.9) */

.top-menu .nav .sub-menu {
    display: none;
    position: absolute;
    left: 0;
    width: 220px;
    top: 100%;
    background: #1a2229;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
}
.top-menu .sub-menu > li > a {
    padding: 5px 20px;
    display: block;
    color: #8a8f94;
    text-decoration: none;
    position: relative;
}
.top-menu .nav .sub-menu > li > a .caret {
    margin-top: -1px;
}
.top-menu .sub-menu .sub-menu {
    background: rgba(0,0,0,0.2);
    position: relative;
    left: 0;
    top: 0;
}
.top-menu .sub-menu > li > a:hover,
.top-menu .sub-menu > li > a:focus,
.top-menu .sub-menu > li.active > a,
.top-menu .sub-menu > li.active > a:hover,
.top-menu .sub-menu > li.active > a:focus {
    color: #fff;
}
.top-menu .nav > li.has-sub:hover > .sub-menu {
    display: block;
}
.top-menu .nav > li.active > a,
.top-menu .nav > li.active > a:hover,
.top-menu .nav > li.active > a:focus{
    background: #242a31;
    color: #fff;
}


/* Page Top Menu - Left & Right Control (Added in V1.9) */

.top-menu .nav > li.menu-control {
    position: absolute;
    top: 0;
    width: 40px;
    background: @black;
    height: 40px;
    margin: 0;
}
.top-menu .nav > li.menu-control > a {
    padding: 0;
    text-align: center;
    line-height: 40px;
}
.top-menu .nav > li.menu-control > a i {
    float: none;
    margin: 0;
    font-size: 18px;
    display: block;
    line-height: 40px;
    color: #fff;
    width: auto;
}
.top-menu .nav > li.menu-control.menu-control-left {
    left: -50px;
}
.top-menu .nav > li.menu-control.menu-control-right {
    right: -50px;
}
@keyframes slideInLeft {
    from { left: -50px; }
    to { left: 0; }
}
@-webkit-keyframes slideInLeft {
    from { left: -50px; }
    to { left: 0; }
}
@keyframes slideInRight {
    from { right: -50px; }
    to { right: 0; }
}
@-webkit-keyframes slideInRight {
    from { right: -50px; }
    to { right: 0; }
}
.top-menu .nav > li.menu-control.menu-control-left.show {
    -webkit-animation: slideInLeft .2s;
    animation: slideInLeft .2s;
    left: 0;
}
.top-menu .nav > li.menu-control.menu-control-right.show {
    -webkit-animation: slideInRight .2s;
    animation: slideInRight .2s;
    right: 0;
}
.top-menu .nav > li.menu-control.menu-control-left > a {
    box-shadow: 5px 0 8px -3px rgba(0,0,0,0.5);
}
.top-menu .nav > li.menu-control.menu-control-right > a {
    box-shadow: -5px 0 8px -3px rgba(0,0,0,0.5);
}
