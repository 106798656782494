/* Predefined Classes */

.row { margin: 0 -10px; }
.row > [class^="col-"] {  padding: 0 10px; }
.row.row-space-0 { margin: 0; }
.row.row-space-2 { margin: 0 -1px; }
.row.row-space-4 { margin: 0 -2px; }
.row.row-space-6 { margin: 0 -3px; }
.row.row-space-8 { margin: 0 -4px; }
.row.row-space-10 { margin: 0 -5px; }
.row.row-space-12 { margin: 0 -6px; }
.row.row-space-14 { margin: 0 -7px; }
.row.row-space-16 { margin: 0 -8px; }
.row.row-space-18 { margin: 0 -9px; }
.row.row-space-18 { margin: 0 -10px; }
.row.row-space-22 { margin: 0 -11px; }
.row.row-space-24 { margin: 0 -12px; }
.row.row-space-26 { margin: 0 -13px; }
.row.row-space-28 { margin: 0 -14px; }
.row.row-space-30 { margin: 0 -15px; }
.row.row-space-0 > [class^="col-"] {  padding: 0; }
.row.row-space-2 > [class^="col-"] {  padding: 0 1px; }
.row.row-space-4 > [class^="col-"] {  padding: 0 2px; }
.row.row-space-6 > [class^="col-"] {  padding: 0 3px; }
.row.row-space-8 > [class^="col-"] {  padding: 0 4px; }
.row.row-space-10 > [class^="col-"] {  padding: 0 5px; }
.row.row-space-12 > [class^="col-"] {  padding: 0 6px; }
.row.row-space-14 > [class^="col-"] {  padding: 0 7px; }
.row.row-space-16 > [class^="col-"] {  padding: 0 8px; }
.row.row-space-18 > [class^="col-"] {  padding: 0 9px; }
.row.row-space-20 > [class^="col-"] {  padding: 0 10px; }
.row.row-space-22 > [class^="col-"] {  padding: 0 11px; }
.row.row-space-24 > [class^="col-"] {  padding: 0 12px; }
.row.row-space-26 > [class^="col-"] {  padding: 0 13px; }
.row.row-space-28 > [class^="col-"] {  padding: 0 14px; }
.row.row-space-30 > [class^="col-"] {  padding: 0 15px; }

.overflow-auto { overflow: auto !important; }
.overflow-hidden { overflow: hidden !important; }
.overflow-visible { overflow: visible !important; }
.overflow-scroll { overflow: scroll !important; }
.overflow-x-hidden { overflow-x: hidden !important; }
.overflow-x-visible { overflow-x: visible !important; }
.overflow-x-scroll { overflow-x: scroll !important; }
.overflow-y-hidden { overflow-y: hidden !important; }
.overflow-y-visible { overflow-y: visible !important; }
.overflow-y-scroll { overflow-y: scroll !important; }

.m-auto { margin: 0 auto !important; }
.m-0 { margin: 0px !important; }
.m-1 { margin: 1px !important; }
.m-2 { margin: 2px !important; }
.m-3 { margin: 3px !important; }
.m-4 { margin: 4px !important; }
.m-5 { margin: 5px !important; }
.m-10 { margin: 10px !important; }
.m-15 { margin: 15px !important; }
.m-20 { margin: 20px !important; }
.m-25 { margin: 25px !important; }
.m-30 { margin: 30px !important; }
.m-35 { margin: 35px !important; }
.m-40 { margin: 40px !important; }

.m-t-0 { margin-top: 0px !important; }
.m-t-1 { margin-top: 1px !important; }
.m-t-2 { margin-top: 2px !important; }
.m-t-3 { margin-top: 3px !important; }
.m-t-4 { margin-top: 4px !important; }
.m-t-5 { margin-top: 5px !important; }
.m-t-10 { margin-top: 10px !important; }
.m-t-15 { margin-top: 15px !important; }
.m-t-20 { margin-top: 20px !important; }
.m-t-25 { margin-top: 25px !important; }
.m-t-30 { margin-top: 30px !important; }
.m-t-35 { margin-top: 35px !important; }
.m-t-40 { margin-top: 40px !important; }

.m-r-0 { margin-right: 0px !important; }
.m-r-1 { margin-right: 1px !important; }
.m-r-2 { margin-right: 2px !important; }
.m-r-3 { margin-right: 3px !important; }
.m-r-4 { margin-right: 4px !important; }
.m-r-5 { margin-right: 5px !important; }
.m-r-10 { margin-right: 10px !important; }
.m-r-15 { margin-right: 15px !important; }
.m-r-20 { margin-right: 20px !important; }
.m-r-25 { margin-right: 25px !important; }
.m-r-30 { margin-right: 30px !important; }
.m-r-35 { margin-right: 35px !important; }
.m-r-40 { margin-right: 40px !important; }

.m-b-0 { margin-bottom: 0px !important; }
.m-b-1 { margin-bottom: 1px !important; }
.m-b-2 { margin-bottom: 2px !important; }
.m-b-3 { margin-bottom: 3px !important; }
.m-b-4 { margin-bottom: 4px !important; }
.m-b-5 { margin-bottom: 5px !important; }
.m-b-10 { margin-bottom: 10px !important; }
.m-b-15 { margin-bottom: 15px !important; }
.m-b-20 { margin-bottom: 20px !important; }
.m-b-25 { margin-bottom: 25px !important; }
.m-b-30 { margin-bottom: 30px !important; }
.m-b-35 { margin-bottom: 35px !important; }
.m-b-40 { margin-bottom: 40px !important; }

.m-l-0 { margin-left: 0px !important; }
.m-l-1 { margin-left: 1px !important; }
.m-l-2 { margin-left: 2px !important; }
.m-l-3 { margin-left: 3px !important; }
.m-l-4 { margin-left: 4px !important; }
.m-l-5 { margin-left: 5px !important; }
.m-l-10 { margin-left: 10px !important; }
.m-l-15 { margin-left: 15px !important; }
.m-l-20 { margin-left: 20px !important; }
.m-l-25 { margin-left: 25px !important; }
.m-l-30 { margin-left: 30px !important; }
.m-l-35 { margin-left: 35px !important; }
.m-l-40 { margin-left: 40px !important; }

.p-0 { padding: 0px !important; }
.p-1 { padding: 1px !important; }
.p-2 { padding: 2px !important; }
.p-3 { padding: 3px !important; }
.p-4 { padding: 4px !important; }
.p-5 { padding: 5px !important; }
.p-10 { padding: 10px !important; }
.p-15 { padding: 15px !important; }
.wrapper { padding: 15px; }
.p-20 { padding: 20px !important; }
.p-25 { padding: 25px !important; }
.p-30 { padding: 30px !important; }
.p-35 { padding: 35px !important; }
.p-40 { padding: 40px !important; }

.p-t-0 { padding-top: 0px !important; }
.p-t-1 { padding-top: 1px !important; }
.p-t-2 { padding-top: 2px !important; }
.p-t-3 { padding-top: 3px !important; }
.p-t-4 { padding-top: 4px !important; }
.p-t-5 { padding-top: 5px !important; }
.p-t-10 { padding-top: 10px !important; }
.p-t-15 { padding-top: 15px !important; }
.p-t-20 { padding-top: 20px !important; }
.p-t-25 { padding-top: 25px !important; }
.p-t-30 { padding-top: 30px !important; }
.p-t-35 { padding-top: 35px !important; }
.p-t-40 { padding-top: 40px !important; }

.p-r-0 { padding-right: 0px !important; }
.p-r-1 { padding-right: 1px !important; }
.p-r-2 { padding-right: 2px !important; }
.p-r-3 { padding-right: 3px !important; }
.p-r-4 { padding-right: 4px !important; }
.p-r-5 { padding-right: 5px !important; }
.p-r-10 { padding-right: 10px !important; }
.p-r-15 { padding-right: 15px !important; }
.p-r-20 { padding-right: 20px !important; }
.p-r-25 { padding-right: 25px !important; }
.p-r-30 { padding-right: 30px !important; }
.p-r-35 { padding-right: 35px !important; }
.p-r-40 { padding-right: 40px !important; }

.p-b-0 { padding-bottom: 0px !important; }
.p-b-1 { padding-bottom: 1px !important; }
.p-b-2 { padding-bottom: 2px !important; }
.p-b-3 { padding-bottom: 3px !important; }
.p-b-4 { padding-bottom: 4px !important; }
.p-b-5 { padding-bottom: 5px !important; }
.p-b-10 { padding-bottom: 10px !important; }
.p-b-15 { padding-bottom: 15px !important; }
.p-b-20 { padding-bottom: 20px !important; }
.p-b-25 { padding-bottom: 25px !important; }
.p-b-30 { padding-bottom: 30px !important; }
.p-b-35 { padding-bottom: 35px !important; }
.p-b-40 { padding-bottom: 40px !important; }

.p-l-0 { padding-left: 0px !important; }
.p-l-1 { padding-left: 1px !important; }
.p-l-2 { padding-left: 2px !important; }
.p-l-3 { padding-left: 3px !important; }
.p-l-4 { padding-left: 4px !important; }
.p-l-5 { padding-left: 5px !important; }
.p-l-10 { padding-left: 10px !important; }
.p-l-15 { padding-left: 15px !important; }
.p-l-20 { padding-left: 20px !important; }
.p-l-25 { padding-left: 25px !important; }
.p-l-30 { padding-left: 30px !important; }
.p-l-35 { padding-left: 35px !important; }
.p-l-40 { padding-left: 40px !important; }

.f-s-0 { font-size: 0px !important; }
.f-s-1 { font-size: 1px !important; }
.f-s-2 { font-size: 2px !important; }
.f-s-3 { font-size: 3px !important; }
.f-s-4 { font-size: 4px !important; }
.f-s-5 { font-size: 5px !important; }
.f-s-6 { font-size: 6px !important; }
.f-s-7 { font-size: 7px !important; }
.f-s-8 { font-size: 8px !important; }
.f-s-9 { font-size: 9px !important; }
.f-s-10 { font-size: 10px !important; }
.f-s-11 { font-size: 11px !important; }
.f-s-12 { font-size: 12px !important; }
.f-s-13 { font-size: 13px !important; }
.f-s-14 { font-size: 14px !important; }
.f-s-15 { font-size: 15px !important; }
.f-s-16 { font-size: 16px !important; }
.f-s-17 { font-size: 17px !important; }
.f-s-18 { font-size: 18px !important; }
.f-s-19 { font-size: 19px !important; }
.f-s-20 { font-size: 20px !important; }

.l-h-0 { line-height: 0px !important; }
.l-h-1 { line-height: 1px !important; }
.l-h-2 { line-height: 2px !important; }
.l-h-3 { line-height: 3px !important; }
.l-h-4 { line-height: 4px !important; }
.l-h-5 { line-height: 5px !important; }
.l-h-6 { line-height: 6px !important; }
.l-h-7 { line-height: 7px !important; }
.l-h-8 { line-height: 8px !important; }
.l-h-9 { line-height: 9px !important; }
.l-h-10 { line-height: 10px !important; }
.l-h-11 { line-height: 11px !important; }
.l-h-12 { line-height: 12px !important; }
.l-h-13 { line-height: 13px !important; }
.l-h-14 { line-height: 14px !important; }
.l-h-15 { line-height: 15px !important; }
.l-h-16 { line-height: 16px !important; }
.l-h-17 { line-height: 17px !important; }
.l-h-18 { line-height: 18px !important; }
.l-h-19 { line-height: 19px !important; }
.l-h-20 { line-height: 20px !important; }
.l-h-20 { line-height: 20px !important; }
.l-h-21 { line-height: 21px !important; }
.l-h-22 { line-height: 22px !important; }
.l-h-23 { line-height: 23px !important; }
.l-h-24 { line-height: 24px !important; }
.l-h-25 { line-height: 25px !important; }
.l-h-26 { line-height: 26px !important; }
.l-h-27 { line-height: 27px !important; }
.l-h-28 { line-height: 28px !important; }
.l-h-29 { line-height: 29px !important; }
.l-h-30 { line-height: 30px !important; }

.t-plus-1 { position: relative!important; top: 1px !important; }
.t-plus-2 { position: relative!important; top: 2px !important; }
.t-plus-3 { position: relative!important; top: 3px !important; }
.t-plus-4 { position: relative!important; top: 4px !important; }
.t-plus-5 { position: relative!important; top: 5px !important; }
.t-plus-6 { position: relative!important; top: 6px !important; }
.t-plus-7 { position: relative!important; top: 7px !important; }
.t-plus-8 { position: relative!important; top: 8px !important; }
.t-plus-9 { position: relative!important; top: 9px !important; }
.t-plus-10 { position: relative!important; top: 10px !important; }

.t-minus-1 { position: relative!important; top: -1px !important; }
.t-minus-2 { position: relative!important; top: -2px !important; }
.t-minus-3 { position: relative!important; top: -3px !important; }
.t-minus-4 { position: relative!important; top: -4px !important; }
.t-minus-5 { position: relative!important; top: -5px !important; }
.t-minus-6 { position: relative!important; top: -6px !important; }
.t-minus-7 { position: relative!important; top: -7px !important; }
.t-minus-8 { position: relative!important; top: -8px !important; }
.t-minus-9 { position: relative!important; top: -9px !important; }
.t-minus-10 { position: relative!important; top: -10px !important; }

.l-plus-1 { position: relative!important; left: 1px !important; }
.l-plus-2 { position: relative!important; left: 2px !important; }
.l-plus-3 { position: relative!important; left: 3px !important; }
.l-plus-4 { position: relative!important; left: 4px !important; }
.l-plus-5 { position: relative!important; left: 5px !important; }
.l-plus-6 { position: relative!important; left: 6px !important; }
.l-plus-7 { position: relative!important; left: 7px !important; }
.l-plus-8 { position: relative!important; left: 8px !important; }
.l-plus-9 { position: relative!important; left: 9px !important; }
.l-plus-10 { position: relative!important; left: 10px !important; }

.l-minus-1 { position: relative!important; left: -1px !important; }
.l-minus-2 { position: relative!important; left: -2px !important; }
.l-minus-3 { position: relative!important; left: -3px !important; }
.l-minus-4 { position: relative!important; left: -4px !important; }
.l-minus-5 { position: relative!important; left: -5px !important; }
.l-minus-6 { position: relative!important; left: -6px !important; }
.l-minus-7 { position: relative!important; left: -7px !important; }
.l-minus-8 { position: relative!important; left: -8px !important; }
.l-minus-9 { position: relative!important; left: -9px !important; }
.l-minus-10 { position: relative!important; left: -10px !important; }

.r-plus-1 { position: relative!important; right: 1px !important; }
.r-plus-2 { position: relative!important; right: 2px !important; }
.r-plus-3 { position: relative!important; right: 3px !important; }
.r-plus-4 { position: relative!important; right: 4px !important; }
.r-plus-5 { position: relative!important; right: 5px !important; }
.r-plus-6 { position: relative!important; right: 6px !important; }
.r-plus-7 { position: relative!important; right: 7px !important; }
.r-plus-8 { position: relative!important; right: 8px !important; }
.r-plus-9 { position: relative!important; right: 9px !important; }
.r-plus-10 { position: relative!important; right: 10px !important; }

.r-minus-1 { position: relative!important; right: -1px !important; }
.r-minus-2 { position: relative!important; right: -2px !important; }
.r-minus-3 { position: relative!important; right: -3px !important; }
.r-minus-4 { position: relative!important; right: -4px !important; }
.r-minus-5 { position: relative!important; right: -5px !important; }
.r-minus-6 { position: relative!important; right: -6px !important; }
.r-minus-7 { position: relative!important; right: -7px !important; }
.r-minus-8 { position: relative!important; right: -8px !important; }
.r-minus-9 { position: relative!important; right: -9px !important; }
.r-minus-10 { position: relative!important; right: -10px !important; }

.b-plus-1 { position: relative!important; bottom: 1px !important; }
.b-plus-2 { position: relative!important; bottom: 2px !important; }
.b-plus-3 { position: relative!important; bottom: 3px !important; }
.b-plus-4 { position: relative!important; bottom: 4px !important; }
.b-plus-5 { position: relative!important; bottom: 5px !important; }
.b-plus-6 { position: relative!important; bottom: 6px !important; }
.b-plus-7 { position: relative!important; bottom: 7px !important; }
.b-plus-8 { position: relative!important; bottom: 8px !important; }
.b-plus-9 { position: relative!important; bottom: 9px !important; }
.b-plus-10 { position: relative!important; bottom: 10px !important; }

.b-minus-1 { position: relative!important; bottom: -1px !important; }
.b-minus-2 { position: relative!important; bottom: -2px !important; }
.b-minus-3 { position: relative!important; bottom: -3px !important; }
.b-minus-4 { position: relative!important; bottom: -4px !important; }
.b-minus-5 { position: relative!important; bottom: -5px !important; }
.b-minus-6 { position: relative!important; bottom: -6px !important; }
.b-minus-7 { position: relative!important; bottom: -7px !important; }
.b-minus-8 { position: relative!important; bottom: -8px !important; }
.b-minus-9 { position: relative!important; bottom: -9px !important; }
.b-minus-10 { position: relative!important; bottom: -10px !important; }

.hide { display: none!important; }

.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }

.pull-left { float: left !important; }
.pull-right { float: right !important; }
.pull-none { float: none !important; }

.f-w-100 { font-weight: 100 !important; }
.f-w-200 { font-weight: 200 !important; }
.f-w-300 { font-weight: 300 !important; }
.f-w-400 { font-weight: 400 !important; }
.f-w-500 { font-weight: 500 !important; }
.f-w-600, .semi-bold { font-weight: 600 !important; }
.f-w-700 { font-weight: 700 !important; }

.table-valign-middle th, 
.table-valign-middle td { 
    vertical-align: middle !important;
}
.table-th-valign-middle th,
.table-td-valign-middle td { 
    vertical-align: middle !important;
}
.table-no-border-left th:first-child,
.table-no-border-left td:first-child {
	border-left: none !important;
}
.table-no-border-bottom tr:last-child td {
	border-bottom: none !important;
}
.table-no-border-top tr:first-child td {
	border-top: none !important;
}
.table-no-border-right th:last-child,
.table-no-border-right td:last-child {
	border-right: none !important;
}
.table-valign-top th, 
.table-valign-top td { 
    vertical-align: top !important;
}
.table-th-valign-top th,
.table-td-valign-top td { 
    vertical-align: top !important;
}
.table-valign-bottom th, 
.table-valign-bottom td { 
    vertical-align: bottom !important;
}
.table-th-valign-bottom th,
.table-td-valign-bottom td { 
    vertical-align: bottom !important;
}
.valign-top { vertical-align: top !important; }
.valign-bottom { vertical-align: bottom !important; }
.valign-middle { vertical-align: middle !important; }
.vertical-box {
    display: table; 
    table-layout: fixed; 
    border-spacing: 0; 
    height: 100%;
    width: 100%;
}
.vertical-box-column {
    display: table-cell;
    vertical-align: top;
    height: 100%;
}
.vertical-box-row {
    display: table-row;
    height: 100%;
}
.vertical-box-row > .vertical-box-cell {
    position: relative;
    height: 100%;
    width: 100%;
    float: none;
}
.vertical-box-row > .vertical-box-cell > .vertical-box-inner-cell {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.vertical-box.with-grid > .vertical-box-column + .vertical-box-column {
	border-left: 1px solid #d9dfe2;
}
.vertical-box.with-grid .vertical-box-row + .vertical-box-row > div,
.vertical-box.with-grid .vertical-box-row + .wrapper,
.vertical-box.with-grid .wrapper + .vertical-box-row > div,
.vertical-box.with-grid .wrapper + .wrapper {
	border-top: 1px solid #d9dfe2;
}
.no-rounded-corner { border-radius: 0 !important; }
.rounded-corner { border-radius: 50px !important; }
.no-box-shadow { box-shadow: none !important; }

.no-border { border: 0 !important; }
.b-0 { border: 0 !important; }
.b-t-0 { border-top: 0 !important; }
.b-r-0 { border-right: 0 !important; }
.b-b-0 { border-bottom: 0 !important; }
.b-l-0 { border-left: 0 !important; }
.b-1 { border: 1px solid #f2f3f4 !important; } 
.b-t-1 { border-top: 1px solid #f2f3f4 !important; }
.b-r-1 { border-right: 1px solid #f2f3f4 !important; }
.b-b-1 { border-bottom: 1px solid #f2f3f4 !important; }
.b-l-1 { border-left: 1px solid #f2f3f4 !important; }
.border-top-1 { border-top: 1px solid #f2f3f4 !important; }
.border-right-1 { border-right: 1px solid #f2f3f4 !important; }
.border-bottom-1 { border-bottom: 1px solid #f2f3f4 !important; }
.border-left-1 { border-left: 1px solid #f2f3f4 !important; }

.text-inverse,
.text-black { color: @black !important; }
.text-black-transparent-9 { color: rgba(45, 53, 60, 0.9) !important; }
.text-black-transparent-8 { color: rgba(45, 53, 60, 0.8) !important; }
.text-black-transparent-7 { color: rgba(45, 53, 60, 0.7) !important; }
.text-black-transparent-6 { color: rgba(45, 53, 60, 0.6) !important; }
.text-black-transparent-5 { color: rgba(45, 53, 60, 0.5) !important; }
.text-black-transparent-4 { color: rgba(45, 53, 60, 0.4) !important; }
.text-black-transparent-3 { color: rgba(45, 53, 60, 0.3) !important; }
.text-black-transparent-2 { color: rgba(45, 53, 60, 0.2) !important; }
.text-black-transparent-1 { color: rgba(45, 53, 60, 0.1) !important; }
.text-black-darker { color: @black_darker !important; }
.text-black-lighter { color: @black_lighter !important; }
a.text-inverse:hover,
a.text-inverse:focus { 
    color: @black_lighter !important; 
}
.text-success,
.text-green { color: @green !important; }
.text-green-transparent-9 { color: rgba(0, 172, 172, 0.9) !important; }
.text-green-transparent-8 { color: rgba(0, 172, 172, 0.8) !important; }
.text-green-transparent-7 { color: rgba(0, 172, 172, 0.7) !important; }
.text-green-transparent-6 { color: rgba(0, 172, 172, 0.6) !important; }
.text-green-transparent-5 { color: rgba(0, 172, 172, 0.5) !important; }
.text-green-transparent-4 { color: rgba(0, 172, 172, 0.4) !important; }
.text-green-transparent-3 { color: rgba(0, 172, 172, 0.3) !important; }
.text-green-transparent-2 { color: rgba(0, 172, 172, 0.2) !important; }
.text-green-transparent-1 { color: rgba(0, 172, 172, 0.1) !important; }
.text-green-darker { color: @green_darker !important; }
.text-green-lighter { color: @green_lighter !important; }
a.text-success:hover,
a.text-success:focus { 
    color: @green_lighter !important; 
}
.text-info,
.text-aqua { color: @aqua !important; }
.text-aqua-transparent-9 { color: rgba(73, 182, 214, 0.9) !important; }
.text-aqua-transparent-8 { color: rgba(73, 182, 214, 0.8) !important; }
.text-aqua-transparent-7 { color: rgba(73, 182, 214, 0.7) !important; }
.text-aqua-transparent-6 { color: rgba(73, 182, 214, 0.6) !important; }
.text-aqua-transparent-5 { color: rgba(73, 182, 214, 0.5) !important; }
.text-aqua-transparent-4 { color: rgba(73, 182, 214, 0.4) !important; }
.text-aqua-transparent-3 { color: rgba(73, 182, 214, 0.3) !important; }
.text-aqua-transparent-2 { color: rgba(73, 182, 214, 0.2) !important; }
.text-aqua-transparent-1 { color: rgba(73, 182, 214, 0.1) !important; }
.text-aqua-darker { color: @aqua_darker !important; }
.text-aqua-lighter { color: @aqua_lighter !important; }
a.text-info:hover,
a.text-info:focus { 
    color: @aqua_lighter !important; 
}
.text-primary,
.text-blue { color: @blue !important; }
.text-blue-transparent-9 { color: rgba(52, 143, 226, 0.9) !important; }
.text-blue-transparent-8 { color: rgba(52, 143, 226, 0.8) !important; }
.text-blue-transparent-7 { color: rgba(52, 143, 226, 0.7) !important; }
.text-blue-transparent-6 { color: rgba(52, 143, 226, 0.6) !important; }
.text-blue-transparent-5 { color: rgba(52, 143, 226, 0.5) !important; }
.text-blue-transparent-4 { color: rgba(52, 143, 226, 0.4) !important; }
.text-blue-transparent-3 { color: rgba(52, 143, 226, 0.3) !important; }
.text-blue-transparent-2 { color: rgba(52, 143, 226, 0.2) !important; }
.text-blue-transparent-1 { color: rgba(52, 143, 226, 0.1) !important; }
.text-blue-darker { color: @blue_darker !important; }
.text-blue-lighter { color: @blue_lighter !important; }
a.text-primary:hover,
a.text-primary:focus { 
    color: @blue_lighter !important; 
}
.text-warning,
.text-orange { color: @orange !important; }
.text-orange-transparent-9 { color: rgba(245, 156, 26, 0.9) !important; }
.text-orange-transparent-8 { color: rgba(245, 156, 26, 0.8) !important; }
.text-orange-transparent-7 { color: rgba(245, 156, 26, 0.7) !important; }
.text-orange-transparent-6 { color: rgba(245, 156, 26, 0.6) !important; }
.text-orange-transparent-5 { color: rgba(245, 156, 26, 0.5) !important; }
.text-orange-transparent-4 { color: rgba(245, 156, 26, 0.4) !important; }
.text-orange-transparent-3 { color: rgba(245, 156, 26, 0.3) !important; }
.text-orange-transparent-2 { color: rgba(245, 156, 26, 0.2) !important; }
.text-orange-transparent-1 { color: rgba(245, 156, 26, 0.1) !important; }
.text-orange-darker { color: @orange_darker !important; }
.text-orange-lighter { color: @orange_lighter !important; }
a.text-warning:hover,
a.text-warning:focus { 
    color: @orange_lighter !important; 
}
.text-danger,
.text-red { color: @red !important; }
.text-red-transparent-9 { color: rgba(255, 91, 87, 0.9) !important; }
.text-red-transparent-8 { color: rgba(255, 91, 87, 0.8) !important; }
.text-red-transparent-7 { color: rgba(255, 91, 87, 0.7) !important; }
.text-red-transparent-6 { color: rgba(255, 91, 87, 0.6) !important; }
.text-red-transparent-5 { color: rgba(255, 91, 87, 0.5) !important; }
.text-red-transparent-4 { color: rgba(255, 91, 87, 0.4) !important; }
.text-red-transparent-3 { color: rgba(255, 91, 87, 0.3) !important; }
.text-red-transparent-2 { color: rgba(255, 91, 87, 0.2) !important; }
.text-red-transparent-1 { color: rgba(255, 91, 87, 0.1) !important; }
.text-red-darker { color: @red_darker !important; }
.text-red-lighter { color: @red_lighter !important; }
a.text-danger:hover,
a.text-danger:focus { 
    color: @red_lighter !important; 
}
.text-white { color: #fff !important; }
.text-white-transparent-9 { color: rgba(255, 255, 255, 0.9) !important; }
.text-white-transparent-8 { color: rgba(255, 255, 255, 0.8) !important; }
.text-white-transparent-7 { color: rgba(255, 255, 255, 0.7) !important; }
.text-white-transparent-6 { color: rgba(255, 255, 255, 0.6) !important; }
.text-white-transparent-5 { color: rgba(255, 255, 255, 0.5) !important; }
.text-white-transparent-4 { color: rgba(255, 255, 255, 0.4) !important; }
.text-white-transparent-3 { color: rgba(255, 255, 255, 0.3) !important; }
.text-white-transparent-2 { color: rgba(255, 255, 255, 0.2) !important; }
.text-white-transparent-1 { color: rgba(255, 255, 255, 0.1) !important; }
a.text-white:hover,
a.text-white:focus { 
    color: @silver !important; 
}
.text-silver { color: @silver !important; }
.text-silver-transparent-9 { color: rgba(240, 243, 244, 0.9) !important; }
.text-silver-transparent-8 { color: rgba(240, 243, 244, 0.8) !important; }
.text-silver-transparent-7 { color: rgba(240, 243, 244, 0.7) !important; }
.text-silver-transparent-6 { color: rgba(240, 243, 244, 0.6) !important; }
.text-silver-transparent-5 { color: rgba(240, 243, 244, 0.5) !important; }
.text-silver-transparent-4 { color: rgba(240, 243, 244, 0.4) !important; }
.text-silver-transparent-3 { color: rgba(240, 243, 244, 0.3) !important; }
.text-silver-transparent-2 { color: rgba(240, 243, 244, 0.2) !important; }
.text-silver-transparent-1 { color: rgba(240, 243, 244, 0.1) !important; }
.text-silver-lighter { color: @silver_lighter !important; }
.text-silver-darker { color: @silver_darker !important; }
a.text-silver:hover,
a.text-silver:focus { 
    color: @silver_darker !important; 
}
.text-grey, 
.text-muted { color: @grey !important; }
.text-grey-transparent-9 { color: rgba(182, 194, 201, 0.9) !important; }
.text-grey-transparent-8 { color: rgba(182, 194, 201, 0.8) !important; }
.text-grey-transparent-7 { color: rgba(182, 194, 201, 0.7) !important; }
.text-grey-transparent-6 { color: rgba(182, 194, 201, 0.6) !important; }
.text-grey-transparent-5 { color: rgba(182, 194, 201, 0.5) !important; }
.text-grey-transparent-4 { color: rgba(182, 194, 201, 0.4) !important; }
.text-grey-transparent-3 { color: rgba(182, 194, 201, 0.3) !important; }
.text-grey-transparent-2 { color: rgba(182, 194, 201, 0.2) !important; }
.text-grey-transparent-1 { color: rgba(182, 194, 201, 0.1) !important; }
.text-grey-darker { color: @grey_darker !important; }
.text-grey-lighter { color: @grey_lighter !important; }
a.text-grey:hover,
a.text-grey:focus { 
    color: @grey_lighter !important; 
}
.text-yellow { color: @yellow !important; }
.text-yellow-transparent-9 { color: rgba(255, 217, 0, 0.9) !important; }
.text-yellow-transparent-8 { color: rgba(255, 217, 0, 0.8) !important; }
.text-yellow-transparent-7 { color: rgba(255, 217, 0, 0.7) !important; }
.text-yellow-transparent-6 { color: rgba(255, 217, 0, 0.6) !important; }
.text-yellow-transparent-5 { color: rgba(255, 217, 0, 0.5) !important; }
.text-yellow-transparent-4 { color: rgba(255, 217, 0, 0.4) !important; }
.text-yellow-transparent-3 { color: rgba(255, 217, 0, 0.3) !important; }
.text-yellow-transparent-2 { color: rgba(255, 217, 0, 0.2) !important; }
.text-yellow-transparent-1 { color: rgba(255, 217, 0, 0.1) !important; }
.text-yellow-darker { color: @yellow_darker !important; }
.text-yellow-lighter { color: @yellow_lighter !important; }
a.text-yellow:hover,
a.text-yellow:focus { 
    color: #e9fb65 !important; 
}
.text-purple { color: @purple !important; }
.text-purple-transparent-9 { color: rgba(114, 124, 182, 0.9) !important; }
.text-purple-transparent-8 { color: rgba(114, 124, 182, 0.8) !important; }
.text-purple-transparent-7 { color: rgba(114, 124, 182, 0.7) !important; }
.text-purple-transparent-6 { color: rgba(114, 124, 182, 0.6) !important; }
.text-purple-transparent-5 { color: rgba(114, 124, 182, 0.5) !important; }
.text-purple-transparent-4 { color: rgba(114, 124, 182, 0.4) !important; }
.text-purple-transparent-3 { color: rgba(114, 124, 182, 0.3) !important; }
.text-purple-transparent-2 { color: rgba(114, 124, 182, 0.2) !important; }
.text-purple-transparent-1 { color: rgba(114, 124, 182, 0.1) !important; }
.text-purple-darker { color: @purple_darker !important; }
.text-purple-lighter { color: @purple_lighter !important; }
a.text-purple:hover,
a.text-purple:focus { 
    color: @purple_lighter !important; 
}
.text-lime { color: @lime !important; }
.text-lime-transparent-9 { color: rgba(144, 202, 75, 0.9) !important; }
.text-lime-transparent-8 { color: rgba(144, 202, 75, 0.8) !important; }
.text-lime-transparent-7 { color: rgba(144, 202, 75, 0.7) !important; }
.text-lime-transparent-6 { color: rgba(144, 202, 75, 0.6) !important; }
.text-lime-transparent-5 { color: rgba(144, 202, 75, 0.5) !important; }
.text-lime-transparent-4 { color: rgba(144, 202, 75, 0.4) !important; }
.text-lime-transparent-3 { color: rgba(144, 202, 75, 0.3) !important; }
.text-lime-transparent-2 { color: rgba(144, 202, 75, 0.2) !important; }
.text-lime-transparent-1 { color: rgba(144, 202, 75, 0.1) !important; }
.text-lime-darker { color: @lime_darker !important; }
.text-lime-lighter { color: @lime_lighter !important; }
a.text-lime:hover,
a.text-lime:focus { 
    color: @lime_darker !important; 
}
.bg-primary,
.bg-blue { background-color: @blue !important; }
.bg-blue-transparent-9 { background-color: rgba(52, 143, 226, 0.9) !important; }
.bg-blue-transparent-8 { background-color: rgba(52, 143, 226, 0.8) !important; }
.bg-blue-transparent-7 { background-color: rgba(52, 143, 226, 0.7) !important; }
.bg-blue-transparent-6 { background-color: rgba(52, 143, 226, 0.6) !important; }
.bg-blue-transparent-5 { background-color: rgba(52, 143, 226, 0.5) !important; }
.bg-blue-transparent-4 { background-color: rgba(52, 143, 226, 0.4) !important; }
.bg-blue-transparent-3 { background-color: rgba(52, 143, 226, 0.3) !important; }
.bg-blue-transparent-2 { background-color: rgba(52, 143, 226, 0.2) !important; }
.bg-blue-transparent-1 { background-color: rgba(52, 143, 226, 0.1) !important; }
.bg-blue-darker { background-color: @blue_darker !important; }
.bg-blue-lighter { background-color: @blue_lighter !important; }
.bg-gradient-blue {
	background: rgb(81,136,218);
	background: -moz-linear-gradient(-45deg, rgba(81,136,218,1) 0%, rgba(52,135,226,1) 100%) !important;
	background: -webkit-linear-gradient(-45deg, rgba(81,136,218,1) 0%,rgba(52,135,226,1) 100%) !important;
	background: linear-gradient(135deg, rgba(81,136,218,1) 0%,rgba(52,135,226,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5188da', endColorstr='#3487e2',GradientType=1 ) !important;
}
.bg-info,
.bg-aqua { background-color: @aqua !important; }
.bg-aqua-transparent-9 { background-color: rgba(73, 182, 214, 0.9) !important; }
.bg-aqua-transparent-8 { background-color: rgba(73, 182, 214, 0.8) !important; }
.bg-aqua-transparent-7 { background-color: rgba(73, 182, 214, 0.7) !important; }
.bg-aqua-transparent-6 { background-color: rgba(73, 182, 214, 0.6) !important; }
.bg-aqua-transparent-5 { background-color: rgba(73, 182, 214, 0.5) !important; }
.bg-aqua-transparent-4 { background-color: rgba(73, 182, 214, 0.4) !important; }
.bg-aqua-transparent-3 { background-color: rgba(73, 182, 214, 0.3) !important; }
.bg-aqua-transparent-2 { background-color: rgba(73, 182, 214, 0.2) !important; }
.bg-aqua-transparent-1 { background-color: rgba(73, 182, 214, 0.1) !important; }
.bg-aqua-darker { background-color: @aqua_darker !important; }
.bg-aqua-lighter { background-color: @aqua_lighter !important; }
.bg-gradient-aqua {
	background: rgb(109,197,222) !important;
	background: -moz-linear-gradient(top, rgba(109,197,222,1) 0%, rgba(73,182,214,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(109,197,222,1) 0%,rgba(73,182,214,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(109,197,222,1) 0%,rgba(73,182,214,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@aqua_lighter', endColorstr='@aqua',GradientType=0 ) !important;
}
.bg-success,
.bg-green { background-color: @green !important; }
.bg-green-transparent-9 { background-color: rgba(0, 172, 172, 0.9) !important; }
.bg-green-transparent-8 { background-color: rgba(0, 172, 172, 0.8) !important; }
.bg-green-transparent-7 { background-color: rgba(0, 172, 172, 0.7) !important; }
.bg-green-transparent-6 { background-color: rgba(0, 172, 172, 0.6) !important; }
.bg-green-transparent-5 { background-color: rgba(0, 172, 172, 0.5) !important; }
.bg-green-transparent-4 { background-color: rgba(0, 172, 172, 0.4) !important; }
.bg-green-transparent-3 { background-color: rgba(0, 172, 172, 0.3) !important; }
.bg-green-transparent-2 { background-color: rgba(0, 172, 172, 0.2) !important; }
.bg-green-transparent-1 { background-color: rgba(0, 172, 172, 0.1) !important; }
.bg-green-darker { background-color: @green_darker !important; }
.bg-green-lighter { background-color: @green_lighter !important; }
.bg-gradient-green {
	background: rgb(52,186,187) !important;
	background: -moz-linear-gradient(-45deg, rgba(52,186,187,1) 0%, rgba(0,172,172,1) 100%) !important;
	background: -webkit-linear-gradient(-45deg, rgba(52,186,187,1) 0%,rgba(0,172,172,1) 100%) !important;
	background: linear-gradient(135deg, rgba(52,186,187,1) 0%,rgba(0,172,172,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34babb', endColorstr='@green',GradientType=1 ) !important;
}
.bg-danger, 
.bg-red { background-color: @red !important; }
.bg-red-transparent-9 { background-color: rgba(255, 91, 87, 0.9) !important; }
.bg-red-transparent-8 { background-color: rgba(255, 91, 87, 0.8) !important; }
.bg-red-transparent-7 { background-color: rgba(255, 91, 87, 0.7) !important; }
.bg-red-transparent-6 { background-color: rgba(255, 91, 87, 0.6) !important; }
.bg-red-transparent-5 { background-color: rgba(255, 91, 87, 0.5) !important; }
.bg-red-transparent-4 { background-color: rgba(255, 91, 87, 0.4) !important; }
.bg-red-transparent-3 { background-color: rgba(255, 91, 87, 0.3) !important; }
.bg-red-transparent-2 { background-color: rgba(255, 91, 87, 0.2) !important; }
.bg-red-transparent-1 { background-color: rgba(255, 91, 87, 0.1) !important; }
.bg-red-darker { background-color: @red_darker !important; }
.bg-red-lighter { background-color: @red_lighter !important; }
.bg-gradient-red {
	background: rgb(255,124,121) !important;
	background: -moz-linear-gradient(top, rgba(255,124,121,1) 0%, rgba(255,91,87,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255,124,121,1) 0%,rgba(255,91,87,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255,124,121,1) 0%,rgba(255,91,87,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@red_lighter', endColorstr='@red',GradientType=0 ) !important;
}
.bg-warning, 
.bg-orange { background-color: @orange !important; }
.bg-orange-transparent-9 { background-color: rgba(245, 156, 26, 0.9) !important; }
.bg-orange-transparent-8 { background-color: rgba(245, 156, 26, 0.8) !important; }
.bg-orange-transparent-7 { background-color: rgba(245, 156, 26, 0.7) !important; }
.bg-orange-transparent-6 { background-color: rgba(245, 156, 26, 0.6) !important; }
.bg-orange-transparent-5 { background-color: rgba(245, 156, 26, 0.5) !important; }
.bg-orange-transparent-4 { background-color: rgba(245, 156, 26, 0.4) !important; }
.bg-orange-transparent-3 { background-color: rgba(245, 156, 26, 0.3) !important; }
.bg-orange-transparent-2 { background-color: rgba(245, 156, 26, 0.2) !important; }
.bg-orange-transparent-1 { background-color: rgba(245, 156, 26, 0.1) !important; }
.bg-orange-darker { background-color: @orange_darker !important; }
.bg-orange-lighter { background-color: @orange_lighter !important; }
.bg-gradient-orange {
	background: rgb(247,176,72) !important;
	background: -moz-linear-gradient(top, rgba(247,176,72,1) 0%, rgba(245,156,26,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(247,176,72,1) 0%,rgba(245,156,26,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(247,176,72,1) 0%,rgba(245,156,26,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@orange_lighter', endColorstr='@orange',GradientType=0 ) !important;
}
.bg-inverse, 
.bg-black { background-color: @black !important; }
.bg-black-transparent-9 { background-color: rgba(45, 53, 60, 0.9) !important; }
.bg-black-transparent-8 { background-color: rgba(45, 53, 60, 0.8) !important; }
.bg-black-transparent-7 { background-color: rgba(45, 53, 60, 0.7) !important; }
.bg-black-transparent-6 { background-color: rgba(45, 53, 60, 0.6) !important; }
.bg-black-transparent-5 { background-color: rgba(45, 53, 60, 0.5) !important; }
.bg-black-transparent-4 { background-color: rgba(45, 53, 60, 0.4) !important; }
.bg-black-transparent-3 { background-color: rgba(45, 53, 60, 0.3) !important; }
.bg-black-transparent-2 { background-color: rgba(45, 53, 60, 0.2) !important; }
.bg-black-transparent-1 { background-color: rgba(45, 53, 60, 0.1) !important; }
.bg-black-darker { background-color: @black_darker !important; }
.bg-black-lighter { background-color: @black_lighter !important; }
.bg-gradient-black {
	background: rgb(88,97,105) !important;
	background: -moz-linear-gradient(-45deg, rgba(88,97,105,1) 0%, rgba(45,53,60,1) 100%) !important;
	background: -webkit-linear-gradient(-45deg, rgba(88,97,105,1) 0%,rgba(45,53,60,1) 100%) !important;
	background: linear-gradient(135deg, rgba(88,97,105,1) 0%,rgba(45,53,60,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#586169', endColorstr='@black',GradientType=1 ) !important;
}
.bg-yellow { background-color: @yellow !important; }
.bg-yellow-transparent-9 { background-color: rgba(255, 217, 0, 0.9) !important; }
.bg-yellow-transparent-8 { background-color: rgba(255, 217, 0, 0.8) !important; }
.bg-yellow-transparent-7 { background-color: rgba(255, 217, 0, 0.7) !important; }
.bg-yellow-transparent-6 { background-color: rgba(255, 217, 0, 0.6) !important; }
.bg-yellow-transparent-5 { background-color: rgba(255, 217, 0, 0.5) !important; }
.bg-yellow-transparent-4 { background-color: rgba(255, 217, 0, 0.4) !important; }
.bg-yellow-transparent-3 { background-color: rgba(255, 217, 0, 0.3) !important; }
.bg-yellow-transparent-2 { background-color: rgba(255, 217, 0, 0.2) !important; }
.bg-yellow-transparent-1 { background-color: rgba(255, 217, 0, 0.1) !important; }
.bg-yellow-darker { background-color: @yellow_darker !important; }
.bg-yellow-lighter { background-color: @yellow_lighter !important; }
.bg-gradient-yellow {
	background: rgb(253,226,72) !important;
	background: -moz-linear-gradient(top, rgba(253,226,72,1) 0%, rgba(255,217,0,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(253,226,72,1) 0%,rgba(255,217,0,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(253,226,72,1) 0%,rgba(255,217,0,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@yellow_lighter', endColorstr='@yellow',GradientType=0 ) !important;
}
.bg-silver { background-color: @silver !important; }
.bg-silver-transparent-9 { background-color: rgba(240, 243, 244, 0.9) !important; }
.bg-silver-transparent-8 { background-color: rgba(240, 243, 244, 0.8) !important; }
.bg-silver-transparent-7 { background-color: rgba(240, 243, 244, 0.7) !important; }
.bg-silver-transparent-6 { background-color: rgba(240, 243, 244, 0.6) !important; }
.bg-silver-transparent-5 { background-color: rgba(240, 243, 244, 0.5) !important; }
.bg-silver-transparent-4 { background-color: rgba(240, 243, 244, 0.4) !important; }
.bg-silver-transparent-3 { background-color: rgba(240, 243, 244, 0.3) !important; }
.bg-silver-transparent-2 { background-color: rgba(240, 243, 244, 0.2) !important; }
.bg-silver-transparent-1 { background-color: rgba(240, 243, 244, 0.1) !important; }
.bg-silver-lighter { background-color: @silver_lighter !important; }
.bg-silver-darker { background-color: @silver_darker !important; }
.bg-gradient-silver {
	background: rgb(244,246,247) !important;
	background: -moz-linear-gradient(top, rgba(244,246,247,1) 0%, rgba(240,243,244,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(244,246,247,1) 0%,rgba(240,243,244,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(244,246,247,1) 0%,rgba(240,243,244,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@silver_lighter', endColorstr='@silver',GradientType=0 ) !important;
}
.bg-grey { background-color: @grey !important; }
.bg-grey-transparent-9 { background-color: rgba(182, 194, 201, 0.9) !important; }
.bg-grey-transparent-8 { background-color: rgba(182, 194, 201, 0.8) !important; }
.bg-grey-transparent-7 { background-color: rgba(182, 194, 201, 0.7) !important; }
.bg-grey-transparent-6 { background-color: rgba(182, 194, 201, 0.6) !important; }
.bg-grey-transparent-5 { background-color: rgba(182, 194, 201, 0.5) !important; }
.bg-grey-transparent-4 { background-color: rgba(182, 194, 201, 0.4) !important; }
.bg-grey-transparent-3 { background-color: rgba(182, 194, 201, 0.3) !important; }
.bg-grey-transparent-2 { background-color: rgba(182, 194, 201, 0.2) !important; }
.bg-grey-transparent-1 { background-color: rgba(182, 194, 201, 0.1) !important; }
.bg-grey-darker { background-color: @grey_darker !important; }
.bg-grey-lighter { background-color: @grey_lighter !important; }
.bg-gradient-grey {
	background: rgb(197,206,212) !important;
	background: -moz-linear-gradient(top, rgba(197,206,212,1) 0%, rgba(182,194,201,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(197,206,212,1) 0%,rgba(182,194,201,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(197,206,212,1) 0%,rgba(182,194,201,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@grey_lighter', endColorstr='@grey',GradientType=0 ) !important;
}
.bg-purple { background-color: @purple !important; }
.bg-purple-transparent-9 { background-color: rgba(114, 124, 182, 0.9) !important; }
.bg-purple-transparent-8 { background-color: rgba(114, 124, 182, 0.8) !important; }
.bg-purple-transparent-7 { background-color: rgba(114, 124, 182, 0.7) !important; }
.bg-purple-transparent-6 { background-color: rgba(114, 124, 182, 0.6) !important; }
.bg-purple-transparent-5 { background-color: rgba(114, 124, 182, 0.5) !important; }
.bg-purple-transparent-4 { background-color: rgba(114, 124, 182, 0.4) !important; }
.bg-purple-transparent-3 { background-color: rgba(114, 124, 182, 0.3) !important; }
.bg-purple-transparent-2 { background-color: rgba(114, 124, 182, 0.2) !important; }
.bg-purple-transparent-1 { background-color: rgba(114, 124, 182, 0.1) !important; }
.bg-purple-darker { background-color: @purple_darker !important; }
.bg-purple-lighter { background-color: @purple_lighter !important; }
.bg-gradient-purple {
	background: rgb(132,87,243) !important;
	background: -moz-linear-gradient(-45deg, rgba(132,87,243,1) 0%, rgba(114,124,182,1) 100%) !important;
	background: -webkit-linear-gradient(-45deg, rgba(132,87,243,1) 0%,rgba(114,124,182,1) 100%) !important;
	background: linear-gradient(135deg, rgba(132,87,243,1) 0%,rgba(114,124,182,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8457f3', endColorstr='@purple',GradientType=1 ) !important;
}
.bg-lime { background-color: @lime !important; }
.bg-lime-transparent-9 { background-color: rgba(144, 202, 75, 0.9) !important; }
.bg-lime-transparent-8 { background-color: rgba(144, 202, 75, 0.8) !important; }
.bg-lime-transparent-7 { background-color: rgba(144, 202, 75, 0.7) !important; }
.bg-lime-transparent-6 { background-color: rgba(144, 202, 75, 0.6) !important; }
.bg-lime-transparent-5 { background-color: rgba(144, 202, 75, 0.5) !important; }
.bg-lime-transparent-4 { background-color: rgba(144, 202, 75, 0.4) !important; }
.bg-lime-transparent-3 { background-color: rgba(144, 202, 75, 0.3) !important; }
.bg-lime-transparent-2 { background-color: rgba(144, 202, 75, 0.2) !important; }
.bg-lime-transparent-1 { background-color: rgba(144, 202, 75, 0.1) !important; }
.bg-lime-darker { background-color: @lime_darker !important; }
.bg-lime-lighter { background-color: @lime_lighter !important; }
.bg-gradient-lime {
	background: rgb(172,215,120) !important;
	background: -moz-linear-gradient(top, rgba(172,215,120,1) 0%, rgba(144,202,75,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(172,215,120,1) 0%,rgba(144,202,75,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(172,215,120,1) 0%,rgba(144,202,75,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@lime_lighter', endColorstr='@lime',GradientType=0 ) !important;
}
.bg-white { background-color: #ffffff !important; }
.bg-white-transparent-9 { background-color: rgba(255, 255, 255, 0.9) !important; }
.bg-white-transparent-8 { background-color: rgba(255, 255, 255, 0.8) !important; }
.bg-white-transparent-7 { background-color: rgba(255, 255, 255, 0.7) !important; }
.bg-white-transparent-6 { background-color: rgba(255, 255, 255, 0.6) !important; }
.bg-white-transparent-5 { background-color: rgba(255, 255, 255, 0.5) !important; }
.bg-white-transparent-4 { background-color: rgba(255, 255, 255, 0.4) !important; }
.bg-white-transparent-3 { background-color: rgba(255, 255, 255, 0.3) !important; }
.bg-white-transparent-2 { background-color: rgba(255, 255, 255, 0.2) !important; }
.bg-white-transparent-1 { background-color: rgba(255, 255, 255, 0.1) !important; }

.text-gradient {
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}
.no-bg, .bg-none { background: none !important; }

.height-xs { height: 150px !important; }
.height-sm { height: 300px !important; }
.height-md { height: 450px !important; }
.height-lg { height: 600px !important; }
.height-full { height: 100% !important; }
.height-0 { height: 0px !important; }
.height-10 { height: 10px !important; }
.height-20 { height: 20px !important; }
.height-30 { height: 30px !important; }
.height-40 { height: 40px !important; }
.height-50 { height: 50px !important; }
.height-60 { height: 60px !important; }
.height-70 { height: 70px !important; }
.height-80 { height: 80px !important; }
.height-90 { height: 90px !important; }
.height-100 { height: 100px !important; }
.height-150 { height: 150px !important; }
.height-200 { height: 200px !important; }
.height-250 { height: 250px !important; }
.height-300 { height: 300px !important; }
.height-350 { height: 350px !important; }
.height-400 { height: 400px !important; }
.height-450 { height: 450px !important; }
.height-500 { height: 500px !important; }
.height-550 { height: 550px !important; }
.height-600 { height: 600px !important; }

.width-xs { width: 150px !important; }
.width-sm { width: 300px !important; }
.width-md { width: 450px !important; }
.width-lg { width: 600px !important; }
.width-full { width: 100% !important; }
.width-0 { width: 0px !important; }
.width-10 { width: 10px !important; }
.width-20 { width: 20px !important; }
.width-30 { width: 30px !important; }
.width-40 { width: 40px !important; }
.width-50 { width: 50px !important; }
.width-60 { width: 60px !important; }
.width-70 { width: 70px !important; }
.width-80 { width: 80px !important; }
.width-90 { width: 90px !important; }
.width-100 { width: 100px !important; }
.width-150 { width: 150px !important; }
.width-200 { width: 200px !important; }
.width-250 { width: 250px !important; }
.width-300 { width: 300px !important; }
.width-350 { width: 350px !important; }
.width-400 { width: 400px !important; }
.width-450 { width: 450px !important; }
.width-500 { width: 500px !important; }
.width-550 { width: 550px !important; }
.width-600 { width: 600px !important; }

.pointer-cursor { cursor: pointer !important; }
.cursor-pointer { cursor: pointer !important; }
.cursor-initial { cursor: initial !important; }
.cursor-move { cursor: move !important; }

.animated {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.fade {
    opacity: 0;
    transition: opacity .3s linear;
}
.text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.text-nowrap {
    white-space: nowrap !important;
}
.underline {
    border-bottom: 1px solid #e2e7eb !important;
}