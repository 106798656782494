.generate-button-with-custom-font-color(@buttonClassName; @defaultColor; @hoverColor; @fontColor) {
  .btn.@{buttonClassName} {
    color: @fontColor;
    background: @defaultColor;
    border-color: @defaultColor;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background: @hoverColor;
      border-color: @hoverColor;
    }

  }
  .open > .dropdown-toggle.@{buttonClassName} {
    &,
    &:hover,
    &:focus {
      background: @hoverColor;
      border-color: @hoverColor;
    }
  }
  .open .dropdown-toggle.@{buttonClassName} {
    background: @hoverColor;
    border-color: @hoverColor;
  }
  .btn-group .btn.@{buttonClassName}:not(.active) + .btn.@{buttonClassName},
  .input-group-btn .btn.@{buttonClassName}:not(.active) + .btn.@{buttonClassName} {
    border-left-color: @hoverColor;
  }
}



// .btn-default
.generate-button-with-custom-font-color(btn-default; @silver; @silver_darker; @body_text_color);

.btn-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

// Make a button look and behave like a link
.btn-link {
  color: inherit;
  font-weight: normal;
  border-radius: 0;
  padding: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
  }
}

.table-op-col, .permission-col {
  button, [role=button] {
    padding: 0;
    color: @grey_darker;
    background: transparent;

    i {
      -webkit-transition: all 200ms;
      -moz-transition: all 200ms;
      -ms-transition: all 200ms;
      -o-transition: all 200ms;
      transition: all 200ms;

      text-shadow: 0 0 0 transparent;
    }

    &.active, &:active, &:focus, &:hover{
      color: darken(@grey_darker, 10%);
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      i {
        text-shadow: 0 8px 8px rgba(0, 0, 0, .2);
      }
    }
  }

  .btn-accept-doc, .btn-restore-doc, .btn-reserve-doc-num {
    color: @green;
    &.active, &:active, &:focus, &:hover {
      color: @green_darker;
    }
  }

  .btn-edit-doc, .btn[class*="btn-edit-"] {
    &.active, &:active, &:focus, &:hover {
      color: @orange;
    }
  }

  .btn-send-doc-doc {
    color: @blue;
    &.active, &:active, &:focus, &:hover {
      color: @blue_darker;
    }
  }
  .btn-forward-doc {
    color: @blue;
    &.forwarded {
      color: @grey_darker;
    }
    &.active, &:active, &:focus, &:hover {
      color: @blue_darker;

      &.forwarded {
        color: darken(@grey_darker, 10%);
      }
    }


  }

  .btn-delete-doc, .btn[class*="btn-delete-"] {
    &.active, &:active, &:focus, &:hover {
      color: @red;
    }
  }

  .btn-toggle-permission {
    color: @grey_lighter;

    &.active {
      color: @blue;
    }

    &:hover {
      color: @grey;
    }
  }
}

.btn-change-mode {
  padding-right: 30px;
  text-align: left;
  i.pull-right {
    margin-right: -22px;
    margin-top: 3px;
  }
}

.generate-outline-button(btn-outline-default; @grey; @grey_darker;);
.generate-outline-button(btn-outline-primary; @blue; @blue_darker;);
.generate-outline-button(btn-outline-success; @green; @green_darker;);
.generate-outline-button(btn-outline-danger; @red; @red_darker;);

.generate-outline-button(@buttonClassName; @defaultColor; @defaultDarker; @hoverColor: #fff;) {
  .btn.@{buttonClassName} {
    color: @defaultColor;
    background-image: none;
    background: transparent;
    border-color: @defaultColor;

    &:hover {
      color: @hoverColor;
      background-color: @defaultDarker;
      border-color: @defaultDarker;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 3px fade(@defaultColor, .5);
    }

    &:active,
    &.active {
      color: @hoverColor;
      background-color: @defaultDarker;
      border-color: @defaultDarker;
    }

    &.disabled,
    &:disabled {
      color: @defaultColor;
      background-color: transparent;
    }

  }
  .open .dropdown-toggle.@{buttonClassName} {
    color: @hoverColor;
    background-color: @defaultDarker;
    border-color: @defaultDarker;
  }

  .btn-group .btn.@{buttonClassName}:not(.active) + .btn.@{buttonClassName},
  .input-group-btn .btn.@{buttonClassName}:not(.active) + .btn.@{buttonClassName} {
    border-left-color: @defaultColor;
  }
}