.sidebar {
  .nav {
    > li.nav-profile {
      background: #10181F;
    }

    > li.active > a {
      background: @main_color;
      i {
        color: white;
      }
      &:focus, &:hover {
        background: @main_color;
      }
    }
  }

}

.mode-list-search {
  label {
    width: 100%;
    padding: 8px;
    margin: 0;

    &:after {
      font-family: "Font Awesome\ 5 Free";
      content: "\f002";
      display: block;
      margin-top: -26px;
      margin-bottom: 8px;
      padding-left: 8px;
      color: #889097;
    }

    input {
      background: @black_darker_2;
      border: none;
      color: @white;
      padding-left: 30px;
    }
  }
}