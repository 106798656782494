//.pagination > li > a, .pagination > li > span {
//  position: relative;
//  float: left;
//  padding: 6px 12px;
//  margin-left: -1px;
//  line-height: 1.42857143;
//  color: #337ab7;
//  text-decoration: none;
//  background-color: #fff;
//  border: 1px solid #ddd;
//}

.table {
  table-layout: fixed;
  > tbody {
    > tr {
      color: #707478;
      font-weight: normal;

      a {
        color: #707478;
        text-decoration: none;

        &:hover {
          color: @main_color;
        }
      }

      &.unread {
        color: #333;
        font-weight: bold;

        a:not(.btn) {
          color: #333;
          &:hover {
            color: @main_color;
          }

        }

      }

      &:hover td {
        background: #fafafa;
      }

      > td {
        vertical-align: middle;
      }

    }
  }

  p {
    margin-bottom: 0;
  }

}

.doc-table {
  tr {
    td {
      white-space: nowrap;
    }
    &.doc-reserve, &.doc-draft {
      .doc-title-col {
        .title-wrap {
          &:before {
            display: inline-block;
            color: #fff;
            font-size: 11px;
            font-weight: normal;
            border-radius: 2px;
            padding: 2px 6px;
            margin-right: 8px;
            .box-shadow(inset 0 0 1px rgba(0, 0, 0, 0.1));
          }

        }
      }

    }
  }

  .sub-detail {
    font-size: 14px;
    font-weight: 200;
    color: #767676;
  }
}

.doc-table {
  tr {
    &.secret-1, &.secret-2, &.secret-3 {
      .doc-title-col {
        .title-wrap {
          &:after {
            color: #aaa;
            margin-left: 8px;
            font-family: "Font Awesome\ 5 Free";
          }
        }

      }
    }

  }
}

.doc-table tr.secret-1 td.doc-title-col div.title-wrap:after {
  content: "\f023";
}

.doc-table tr.secret-2 td.doc-title-col div.title-wrap:after {
  content: "\f023";
}

.doc-table tr.secret-3 td.doc-title-col div.title-wrap:after {
  content: "\f023";
}

.doc-table tr td.doc-title-col div.date-wrap {
  color: #aaa;
  font-size: 14px;
  font-weight: normal;
}

.doc-title-col {
  min-width: 250px;
  width: 100% !important;
  font-size: 16px;
}

.doc-select-col {
  width: 4px;
}

.table-id-col {
  width: 46px;
  padding: 10px 4px;
}

.table-op-col {
  width: 62px;
  padding: 10px 4px;
  text-align: center;
}

.doc-receive-num-col {
  width: 82px;
}

.doc-send-num-col {
  width: 80px;
}

.doc-num-col {
  width: 140px;
}

.doc-from-col {
  width: 200px;

  .sub-detail {
    font-size: 12px;
  }

}

.doc-attach-col {
  width: 8px;
  font-size: 16px;
  padding: 0 !important;
  text-align: center;
}

.doc-date-col {
  width: 128px;
  text-align: right;
  p {
    margin: 0;
  }
  .doc-accept-time {
    color: #aaa;
    font-size: 14px;
    font-weight: normal;
  }
  .sub-detail {
    font-size: 12px;
  }
}

.dept-id-col {
  width: 80px;
}

.dept-prefix-col {
  width: 140px;
}

#attach_table {
  .file-index {
    width: 72px;
  }
  .file-owner {

  }
  .file-created {
    width: 128px;
  }
  .file-link {
    width: 48px;
    i {
      font-size: 2em;
    }
  }
}

table.dataTable {
  td, th {
    &.select-checkbox {

      position: relative;
      width: 40px;

      &:before, &:after {
        display: block;
        position: absolute;
        top: ~"calc(50% - 10px)";
        left: 50%;
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        font-family: Font Awesome\ 5 Free;
        font-size: 1.2em;
        cursor: pointer;
      }

      &:before {
        font-weight: normal;
        content: '\f0c8';
        color: @grey;
      }

      &:after {

      }
    }
  }

  tr {
    &.selected {
      td, th {
        &.select-checkbox {
          &:after {
            font-weight: bold;
            content: '\f14a';
            color: @main_color;
          }
        }
      }
    }
  }
}

.dataTables_wrapper {
  .select-info, .select-item {
    margin-left: 0.5em
  }
}

.dataTables_empty {
  width: 100%;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 54px;
  width: ~'calc(100% - 20px)';
  padding: 10px;
  -webkit-box-shadow: 0px 8px 4px -2px @white;
  -moz-box-shadow: 0px 8px 4px -2px @white;
  box-shadow: 0px 8px 4px -2px @white;
}

img.messenger-sign {
  max-width: 100%;
}

@media screen and (max-width: 640px) {
  .dataTables_wrapper {
    .select-info, .select-item {
      margin-left: 0;
      display: block
    }
  }

}

@media (max-width: 767px) {
  table.doc-table {
    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 10px;
      border-top: none;
      background-color: white;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
      -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
      -webkit-transition: box-shadow .2s;
      -moz-transition: box-shadow .2s;
      -ms-transition: box-shadow .2s;
      -o-transition: box-shadow .2s;
      transition: box-shadow .2s;

      &:hover {
        td {
          background: none;
        }
      }

    }

    td {
      display: inline-block;
    }

    &.table > tbody > tr {
      > td {
        border-top: none;
      }

      &:hover {
        -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        > td {
          background: none;
        }
      }
    }
  }

  div.dataTables_wrapper div.dataTables_processing {
    top: 10px;
  }


}

.dataTables_filter {
  label {
    width: 100%;
  }
}


