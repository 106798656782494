.modal.fade .modal-dialog {
  -webkit-transition: transform 0.3s ease-out, width 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out, width 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out, width 0.3s ease-out;
  -o-transition: transform 0.3s ease-out, width 0.3s ease-out;
  transition: transform 0.3s ease-out, width 0.3s ease-out;
}

.success-message {
  .success-receive-num {
    font-size: 54px;
    font-weight: bold;
  }

  .success-accept-time {
    font-size: 36px;
    font-weight: bold;
  }
}