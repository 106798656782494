/* Option - Fixed Header */

.page-header-fixed {
    padding-top: 50px;
}
.page-header-fixed #header {
	position: fixed;
}
.page-header-fixed.has-scroll #header {
	box-shadow: 0 0 24px rgba(0,0,0,0.15);
}