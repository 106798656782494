/* Plugins - jQuery Autocomplete */

.ui-widget {
    font-family: inherit;
    font-size: 12px;
}
.ui-autocomplete {
    padding: 5px 0 !important;
    box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.25) !important;
    border: 1px solid #ccc !important;
	border: none !important;
    font-size: 12px !important;
}
.ui-menu .ui-menu-item a {
    padding: 5px 10px;
}
body .ui-autocomplete.ui-menu .ui-menu-item-wrapper {
	padding: 5px 15px;
}
.ui-stats-hover, 
.ui-widget-header .ui-stats-hover, 
.ui-widget-content .ui-stats-hover,
.ui-stats-focus, 
.ui-widget-header .ui-stats-focus,
.ui-widget-content .ui-stats-focus {
    border: none !important;
    background: @blue !important;
    color: #fff !important;
    margin: 0 !important;
    border-radius: 0 !important;
    
}
.ui-state-hover, 
.ui-widget-header .ui-state-hover, 
.ui-widget-content .ui-state-hover, 
.ui-state-focus,  
.ui-widget-header .ui-state-focus,
.ui-widget-content .ui-state-focus {
	background: #edf0f5;
	border: none;
    margin: 0 !important;
    border-radius: 0;
}
.ui-state-active, 
.ui-widget-content .ui-state-active, 
.ui-widget-header .ui-state-active, 
a.ui-button:active, .ui-button:active, 
.ui-button.ui-state-active:hover {
	background: @blue;
	border-color: @blue;
}