/* Component - List Group */

.list-group-item {
	padding: 10px 15px;
}
a.list-group-item, 
button.list-group-item {
    color: @black_lighter;
}
a.list-group-item.active, 
a.list-group-item.active:hover, 
a.list-group-item.active:focus {
    background: @blue;
}
a.list-group-item.active > .badge, 
.nav-pills > .active > a > .badge {
    color: @black_darker;
}
.nav-tabs.card-header-tabs {
	background: none;
	margin-bottom: -11px;
}