/* Page Sidebar */

.sidebar {
    width: 220px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 50px;
    background: @black;
    z-index: 1010;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.sidebar-bg {
    background: @black;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 220px;
    z-index: 1000;
}
.sidebar .nav {
	display: block;
}
.sidebar .nav > li {
	position: relative;
}
.sidebar .nav > li > a {
    padding: 7px 20px;
    line-height: 20px;
    color: #a8acb1;
    display: block;
    text-decoration: none;
}
.sidebar .nav > li > a:hover,
.sidebar .nav > li > a:focus {
    background: none;
    color: #fff;
}
.sidebar .nav > li:before,
.sidebar .nav > li:after,
.sidebar .nav > li > a:before,
.sidebar .nav > li > a:after {
    content: '';
    clear: both;
    display: table;
}
.sidebar .nav > li > a i {
    float: left;
    margin-right: 10px;
    width: 14px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}
.sidebar .nav > li > a i[class^="ion-"] {
    margin-right: 8px;
    width: 18px;
    font-size: 18px;
    margin-left: -2px;
}
.sidebar .nav > li > a .icon-img {
	float: left;
	margin-right: 10px;
	margin-top: 3px;
	width: 14px;
	height: 14px;
	overflow: hidden;
	border-radius: 3px;
}
.sidebar .nav > li > a .icon-img img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}
.sidebar .nav > li > a .badge {
    margin-top: 1px;
    padding: 3px 8px;
    background: #1b1f24;
    font-weight: 600;
    font-size: 10px;
}
.sidebar .nav > li.active > a {
	position: relative;
	z-index: 10;
}
.sidebar .nav > li.active > a, 
.sidebar .nav > li.active > a:hover, 
.sidebar .nav > li.active > a:focus {
    color: #fff;
    background: #242a31;
}
.sidebar .nav > li.active > a i {
	color: @green;
}
.sidebar .nav > li.active > a .label.label-success,
.sidebar .nav > li.active > a .badge.badge-success {
    background: @green_darker;
}
.sidebar .nav > li.nav-header {
    margin: 0;
    padding: 15px 20px 3px;
    line-height: 20px;
    font-size: 11px;
    color: #889097;
    font-weight: 600;
}
.sidebar .nav > li.nav-header a {
    padding: 0;
    margin: 0;
    display: inline;
}
.sidebar .nav > li.nav-header a:hover,
.sidebar .nav > li.nav-header a:focus {
    background: none;
    color: #fff;
}
.sidebar .nav > li.nav-header a i {
    float: none;
    margin: 0;
}
.sidebar .nav > li.nav-profile {
    padding: 20px;
    color: #fff;
    background: #1a2229;
    overflow: hidden;
}
.sidebar .nav > li.nav-profile a {
    padding: 0;
}
.sidebar .nav > li.nav-profile > a {
	margin: -20px;
	padding: 20px;
	display: block;
	color: #fff;
}
.sidebar .nav > li.nav-profile .image {
    width: 34px;
    height: 34px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}
.sidebar .nav > li.nav-profile .image img {
    max-width: 100%;
    max-height: 100%;
}
.sidebar .nav > li.nav-profile .info {
    font-size: 14px;
    position: relative;
}
.sidebar .nav > li.nav-profile .info .caret {
	margin-top: 2px;
}
.sidebar .nav > li.nav-profile .info small {
    display: block;
    color: rgba(255,255,255,0.75);
    font-size: 12px;
    margin-bottom: -5px;
}
.sidebar .nav > li.nav-profile .cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: url('images/cover-sidebar-user.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}
.sidebar .nav > li.nav-profile .cover.with-shadow:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transition: all .2s linear;
	background: rgba(26, 34, 41, 0.75);
}
.sidebar .nav > li.nav-profile a:hover .cover.with-shadow:before,
.sidebar .nav > li.nav-profile a:focus .cover.with-shadow:before {
	opacity: 0.75;
}
.sidebar .nav > li.nav-widget {
    padding: 10px 20px;
}
.sidebar .nav > li.nav-widget i {
    margin-right: auto;
}
.sidebar .nav.nav-profile {
	padding: 0;
    border-bottom: 1px solid #242a31;
    padding-bottom: 5px;
    display: none;
}
.sidebar .nav.nav-profile.active {
	display: block;
}


/* Page Sidebar - Grid Style */

.sidebar.sidebar-grid .nav > li > a {
    border-bottom: 1px solid #46505a;
    border-top: 1px solid #46505a;
}
.sidebar.sidebar-grid .nav > li.active > a {
    border-color: #242a31;
    z-index: 10;
}
.sidebar.sidebar-grid .nav > li.expanding > a,
.sidebar.sidebar-grid .nav > li.expand > a {
    border-bottom-color: transparent;
}
.sidebar.sidebar-grid .nav > li + li {
    margin-top: -1px;
}


/* Page Sidebar - Gradient Style */

.gradient-enabled .sidebar .nav > li.active > a .label.label-theme,
.gradient-enabled .sidebar .nav > li.active > a .badge {
    background: rgba(0,0,0,0.4);
}


/* Page Sidebar - Sub Menu */

.sidebar li > a .caret,
.float-sub-menu li > a .caret {
    display: block;
    float: right;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    border: none;
}
.sidebar li > a .caret:before,
.float-sub-menu li > a .caret:before {
	content: '\f054';
    display: block;
    text-align: center;
    transition: all .2s linear;
    opacity: 0.5;
}
.sidebar li.active > a .caret:before,
.sidebar li.expanding > a .caret:before,
.sidebar li.expand > a .caret:before,
.float-sub-menu li.active > a .caret:before,
.float-sub-menu li.expanding > a .caret:before,
.float-sub-menu li.expand > a .caret:before {
	opacity: 1.0;
	transform: rotate(90deg);
}
.sidebar li.closing > a .caret:before,
.sidebar li.closed > a .caret:before,
.float-sub-menu li.closing > a .caret:before,
.float-sub-menu li.closed > a .caret:before {
	transform: rotate(0deg);
}
.sidebar .sub-menu {
    list-style-type: none;
    padding: 5px 0 10px 39px;
    margin: 0;
    position: relative;
    display: none;
}
.sidebar .sub-menu > li {
	position: relative;
}
.sidebar .sub-menu > li:before {
    content: '';
    position: absolute;
    left: -13px;
    top: 0;
    bottom: 0;
    width: 2px;
}
.sidebar .sub-menu > li:after {
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    border: 1px solid #889097;
    top: 11px;
    border-radius: 4px;
    margin-top: -2px;
    z-index: 10;
    background: @black;
}
.sidebar .sub-menu > li:first-child:before {
	top: -14px;
}
.sidebar .sub-menu > li:first-child:last-child:before {
	height: 27px;
}
.sidebar .sub-menu > li:last-child:before {
	bottom: auto;
	height: 13px;
}
.sidebar .sub-menu > li:before,
.sidebar .sub-menu > li > a:after,
.sidebar .sub-menu > li.has-sub > a:before {
	background: #242a31;
}
.sidebar .sub-menu > li.active:after {
    border-color: @green;
}
.sidebar .sub-menu > li > a {
    padding: 3px 20px 3px 15px;
    display:block;
    color: #889097;
    text-decoration: none;
    position: relative;
}
.sidebar .sub-menu > li > a:hover,
.sidebar .sub-menu > li > a:focus,
.sidebar .sub-menu > li.active > a,
.sidebar .sub-menu > li.active > a:hover,
.sidebar .sub-menu > li.active > a:focus {
    color: #fff;
}
.sidebar .sub-menu > li > a:after {
	content: '';
	position: absolute;
	left: -11px;
	top: 11px;
	width: 11px;
	height: 2px;
}
.sidebar .sub-menu > li.has-sub > a:before {
	content: '';
	position: absolute;
	left: 2px;
	top: 11px;
	bottom: 11px;
	width: 2px;
}
.sidebar .sub-menu .sub-menu {
    padding: 0 0 0 15px;
    background: none;
}
.sidebar .nav > li li.has-sub.active > a {
    color: #889097;
}
.sidebar .has-sub.active > .sub-menu {
    display: block;
}
.sidebar .nav .sub-menu > li > a .caret {
    margin-top: -1px;
    left: -5px;
    color: #a3a7ac;
}


/* Page Sidebar - Minified Style */

.page-sidebar-minified .sidebar {
    width: 60px;
    position: absolute;
}
.page-sidebar-minified .sidebar-bg {
    width: 60px;
}
.page-sidebar-minified .content {
    margin-left: 60px;
}
.page-sidebar-minified .footer {
    margin-left: 85px;
}
.page-sidebar-minified.page-sidebar-fixed .sidebar {
	position: fixed;
}
.page-sidebar-minified:not(.page-sidebar-fixed) .sidebar .slimScrollDiv,
.page-sidebar-minified:not(.page-sidebar-fixed) .sidebar .slimScrollDiv > div {
    overflow: visible !important;
    height: auto !important;
}
.page-sidebar-minified .sidebar.sidebar-right .slimScrollDiv,
.page-sidebar-minified .sidebar.sidebar-right .slimScrollDiv > div,
.page-sidebar-minified.page-sidebar-fixed .sidebar .slimScrollDiv,
.page-sidebar-minified.page-sidebar-fixed .sidebar .slimScrollDiv > div {
    overflow: hidden !important;
}
.page-sidebar-minified .sidebar .nav > li > a {
    padding: 10px 20px;
}
.page-sidebar-minified .sidebar .nav-profile,
.page-sidebar-minified .sidebar .nav-header,
.page-sidebar-minified .sidebar .nav > li > a > span {
    display: none;
}
.page-sidebar-minified .sidebar .caret {
    position: absolute;
    top: 10px;
    right: 5px;
}
.page-sidebar-minified .sidebar .caret:before,
.page-sidebar-minified .sidebar li.active .caret:before,
.page-sidebar-minified .sidebar li.expanding .caret:before,
.page-sidebar-minified .sidebar li.expand .caret:before { 
	transform: rotate(0deg);
}
.page-sidebar-minified .sidebar .nav > li > a > i {
    margin: 0;
}
.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    width: 220px;
    display: none;
    padding: 10px 0 10px 30px;
    margin: 0;
    background: #242a31;
}
.page-sidebar-minified .sidebar-minify-btn i:before {
    content: '\f101';
}
.page-sidebar-minified.page-with-right-sidebar .content {
    margin-right: 60px;
    margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .footer {
    margin-right: 90px;
    margin-left: 30px;
}
.page-sidebar-minified.page-with-right-sidebar .sub-menu {
    left: auto !important;
    right: 100%;
}
.page-sidebar-minified.page-with-right-sidebar .nav > li.has-sub > a .caret {
    position: absolute;
    left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .nav > li.has-sub > a .caret:before {
	content: '\f104';
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu .caret {
    left: 0;
    top: 2px;
    border-left: 4px solid transparent !important;
    border-right: 4px solid !important;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .nav li.has-sub > .sub-menu {
    padding: 10px 30px 10px 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu:before {
    right: 26px;
    left: auto;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu > li > a:before {
    right: 0;
    left: auto;
    margin-right: -6px;
    margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar-minify-btn i:before {
    content: '\f100';
}
.page-sidebar-minified .sidebar .slimScrollBar,
.page-sidebar-minified .sidebar .slimScrollRail {
    display: none !important;
}
.page-sidebar-minified .sidebar.sidebar-right .slimScrollBar {
    display: block !important;
}
.sidebar-minify-btn {
    margin: 10px 0;
    float: right;
    padding: 5px 20px 5px 10px !important;
    background: #1b1f24;
    color: #fff;
    border-radius: 20px 0 0 20px;
}
.sidebar-minify-btn:hover,
.sidebar-minify-btn:focus {
	background: @black_lighter !important;
}
.sidebar-minify-btn i {
    margin: 0 !important;
    color: #fff;
}


/* Page Sidebar - Light Style (Added in V1.7) */

.page-with-light-sidebar .sidebar,
.page-with-light-sidebar .sidebar-bg {
    background: #fff;
}
.page-with-light-sidebar .sidebar .nav > li > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li > a {
    color: @black_lighter;
    font-weight: 600;
}
.page-with-light-sidebar .sidebar .nav > li.nav-profile a,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active a,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active a:hover,
.page-with-light-sidebar .sidebar .nav > li.nav-profile.active a:focus {
	color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu,
.page-sidebar-minified.page-with-light-sidebar .sidebar .nav li.has-sub > .sub-menu,
.page-sidebar-minified.page-with-light-sidebar .sidebar .nav > li.has-sub:focus > a, 
.page-sidebar-minified.page-with-light-sidebar .sidebar .nav > li.has-sub:hover > a {
    background: none;
    color: @black;
}
.page-with-light-sidebar .sidebar .nav > li.active > a {
    color: #fff;
}
.page-with-light-sidebar .sidebar .nav.nav-profile {
	border-bottom: 1px solid #B1B9C0;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu:before {
    background: #B1B9C0;
}
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn,
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:hover,
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:focus,
.page-with-light-sidebar .sidebar .nav > li.active.expand > a,
.page-with-light-sidebar .sidebar .nav > li.active > a,
.page-with-light-sidebar .sidebar .nav > li.active > a:hover,
.page-with-light-sidebar .sidebar .nav > li.active > a:focus,
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active > a,
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active:hover > a,
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active:focus > a {
    color: @black;
    background: #E6E9EA;
    font-weight: 600;
}
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:hover,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:focus,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active.expand > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn,
.flat-black .page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:hover,
.flat-black .page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:focus {
    background: #e0e0e0;
}
.page-with-light-sidebar .sidebar .nav > li.expand > a,
.page-with-light-sidebar .sidebar .nav > li > a:hover,
.page-with-light-sidebar .sidebar .nav > li > a:focus {
    background: none;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu > li.active > a:before {
    color: @black_lighter;
}
.page-with-light-sidebar .sidebar .sub-menu > li > a {
    font-weight: normal;
}
.page-with-light-sidebar .sidebar .sub-menu > li.active > a {
	font-weight: 600;
}
.page-with-light-sidebar .sidebar .sub-menu > li > a:focus, 
.page-with-light-sidebar .sidebar .sub-menu > li > a:hover,
.page-with-light-sidebar .sidebar .sub-menu > li.active > a, 
.page-with-light-sidebar .sidebar .sub-menu > li.active > a:focus, 
.page-with-light-sidebar .sidebar .sub-menu > li.active > a:hover,
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li.active > a {
    color: #333;
}
.flat-black .page-with-light-sidebar .sidebar .sub-menu,
.flat-black .page-with-light-sidebar .sidebar .nav > li.nav-profile,
.flat-black .page-with-light-sidebar.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu {
    background: #f5f5f5;
}
.page-with-light-sidebar .sidebar .sub-menu > li:before, 
.page-with-light-sidebar .sidebar .sub-menu > li > a:after, 
.page-with-light-sidebar .sidebar .sub-menu > li.has-sub > a:before {
	background: #e7e9ea;
}
.page-with-light-sidebar .sidebar .sub-menu > li:after {
	background: #fff;
}
.page-with-light-sidebar .sidebar .slimScrollBar {
	background: #000 !important;
}


/* Page Sidebar - Wide Style (Added in V1.7) */

.page-with-wide-sidebar .sidebar,
.page-with-wide-sidebar .sidebar+.sidebar-bg,
.page-with-wide-sidebar.page-sidebar-minified .sidebar.sidebar-right,
.page-with-wide-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right {
    width: 250px;
}
.page-with-wide-sidebar .content {
    margin-left: 250px;
}
.page-with-wide-sidebar.page-with-right-sidebar:not(.page-sidebar-minified) .content {
    margin-right: 250px;
    margin-left: 0;
}
.page-with-wide-sidebar.page-with-two-sidebar .content {
    margin-left: 250px;
    margin-right: 250px;
}
.page-with-wide-sidebar.page-sidebar-minified .sidebar,
.page-with-wide-sidebar.page-sidebar-minified .sidebar + .sidebar-bg {
    width: 60px;
}
.page-with-wide-sidebar.page-sidebar-minified:not(.page-with-right-sidebar) .content {
    margin-left: 60px;
}
.page-with-wide-sidebar .footer {
    margin-left: 280px;
}
.page-with-wide-sidebar.page-with-right-sidebar:not(.page-sidebar-minified) .footer {
	margin-left: 30px;
	margin-right: 280px;
}


/* Page Sidebar - Transparent Style (Added in V1.9) */

.sidebar.sidebar-transparent {
    background: none;
}
.sidebar.sidebar-transparent .nav > li.nav-profile,
.sidebar.sidebar-transparent .nav > li.nav-profile.active {
    background: rgba(0, 0, 0, 0.25);
}
.sidebar.sidebar-transparent .nav > li.nav-profile a:hover,
.sidebar.sidebar-transparent .nav > li.nav-profile a:focus,
.sidebar.sidebar-transparent .nav > li.nav-profile .cover,
.sidebar.sidebar-transparent .nav > li.nav-profile .cover.with-shadow:before {
	background: none;
}
.sidebar.sidebar-transparent .sub-menu {
    background: none;
}
.page-sidebar-toggled.page-sidebar-minified .sidebar.sidebar-transparent + .sidebar-bg,
.sidebar.sidebar-transparent + .sidebar-bg {
    background: url(images/cover-sidebar.jpg);
    background-size: cover;
}
.sidebar.sidebar-transparent + .sidebar-bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(45, 53, 60, 0.75);
}
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub .sub-menu {
    background: rgba(26, 34, 41, 0.85);
}
.sidebar.sidebar-transparent .nav > li.nav-header {
    color: #fff;
}
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub:hover > a, 
.page-sidebar-minified .sidebar.sidebar-transparent .nav > li.has-sub:focus > a,
.sidebar.sidebar-transparent .nav > li.expand > a, 
.sidebar.sidebar-transparent .nav > li > a:focus, 
.sidebar.sidebar-transparent .nav > li > a:hover {
	background: none;
	color: #fff;
}
.sidebar.sidebar-transparent .has-sub.active > .sub-menu {
	overflow: hidden;
}
.sidebar.sidebar-transparent .nav > li.active > a, 
.sidebar.sidebar-transparent .nav > li.active > a:hover, 
.sidebar.sidebar-transparent .nav > li.active > a:focus {
    background: rgba(0, 0, 0, 0.3);
}
.sidebar.sidebar-transparent .sub-menu > li:before, 
.sidebar.sidebar-transparent .sub-menu > li > a:after, 
.sidebar.sidebar-transparent .sub-menu > li.has-sub > a:before {
    background: rgba(0, 0, 0, 0.3);
}


/* Page Sidebar - Right Sidebar (Added in V4.0) */

.page-with-right-sidebar .sidebar {
	text-align: right;
}
.page-with-right-sidebar .sidebar .nav > li > a .icon-img,
.page-with-right-sidebar .sidebar .nav > li > a i {
	float: right;
	margin-left: 10px;
	margin-right: 0;
}
.page-with-right-sidebar .sidebar .sub-menu {
	padding-left: 0;
	padding-right: 39px;
}
.page-with-right-sidebar .sidebar .sub-menu > li:before {
	left: auto;
	right: -13px;
}
.page-with-right-sidebar .sidebar .sub-menu > li:after {
	left: auto;
	right: 0;
}
.page-with-right-sidebar .sidebar .sub-menu > li > a {
	padding-left: 20px;
	padding-right: 15px;
}
.page-with-right-sidebar .sidebar .sub-menu > li > a:after {
	left: auto;
	right: -11px;
}
.page-with-right-sidebar .sidebar .nav > li.nav-profile .image {
	margin-left: auto;
	margin-right: 0;
}
.page-with-right-sidebar .sidebar .sub-menu .sub-menu {
	padding-left: 0;
	padding-right: 15px;
}
.page-with-right-sidebar.page-sidebar-minified .sidebar li > a .caret {
	margin-left: 5px;
}
.page-with-right-sidebar.page-sidebar-minified .sidebar li > a .caret:before {
	transform: none !important;
}
.page-with-right-sidebar .float-sub-menu-line,
.page-with-right-sidebar .float-sub-menu-arrow,
.page-with-right-sidebar .float-sub-menu li:after {
	left: auto;
	right: 0;
}
.page-with-right-sidebar .sidebar li.active > a .caret:before, 
.page-with-right-sidebar .sidebar li.expanding > a .caret:before, 
.page-with-right-sidebar .sidebar li.expand > a .caret:before, 
.page-with-right-sidebar .float-sub-menu li.active > a .caret:before, 
.page-with-right-sidebar .float-sub-menu li.expanding > a .caret:before, 
.page-with-right-sidebar .float-sub-menu li.expand > a .caret:before {
	transform: rotate(-90deg);
}
.page-with-right-sidebar .float-sub-menu {
	padding-left: 0;
	padding-right: 39px;
	text-align: right;
}
.page-with-right-sidebar .float-sub-menu li > a {
	padding-left: 15px;
	padding-right: 20px;
}
.page-with-right-sidebar .float-sub-menu li > a .caret,
.page-with-right-sidebar .sidebar li > a .caret {
	float: left;
	margin-left: -5px;
	margin-right: 2px;
	font-size: 16px;
}
.page-with-right-sidebar .float-sub-menu .sub-menu {
	padding-left: 0;
	padding-right: 15px;
}
.page-with-right-sidebar .float-sub-menu li > a .caret:before,
.page-with-right-sidebar .sidebar li > a .caret:before {
	content: '\f104';
}
.page-with-right-sidebar .float-sub-menu li:before {
	right: -13px;
	left: auto;
}
.page-with-right-sidebar .float-sub-menu li > a:after {
	right: -11px;
	left: auto;
}


/* Page Sidebar - Floating Sub Menu (Added in V4.0) */

.float-sub-menu-container {
	position: fixed;
	width: 220px;
	margin: 0;
	background: #242a31;
	z-index: 1020;
}
.float-sub-menu {
	padding: 9px 0 9px 39px;
	margin: 0;
	list-style-type: none;
}
.float-sub-menu .sub-menu {
	display: none;
	list-style-type: none;
}
.float-sub-menu .active > .sub-menu {
	display: block;
}
.float-sub-menu li {
	position: relative;
}
.float-sub-menu li:first-child:before {
	top: -14px;
}
.float-sub-menu > li:first-child:before {
	top: 11px;
}
.float-sub-menu-arrow {
	position: absolute;
	top: 20px;
	left: 0;
	width: 28px;
	height: 2px;
	background: #2e353c;
}
.float-sub-menu-line {
	position: absolute;
	top: 20px;
	left: 26px;
	width: 2px;
	background: #2e353c;
}
.float-sub-menu .float-sub-menu-arrow:before,
.float-sub-menu .float-sub-menu-arrow:after {
	display: none !important;
}
.float-sub-menu li:before,
.float-sub-menu li > a:after,
.float-sub-menu li.has-sub > a:before {
	background: @black;
}
.float-sub-menu li:before {
	content: '';
	position: absolute;
	left: -13px;
	top: 0;
	bottom: 0;
	width: 2px;
}
.float-sub-menu li:last-child:before {
	bottom: auto;
	height: 13px;
}
.float-sub-menu > li:first-child:last-child:before {
	height: 0px !important;
}
.float-sub-menu li:first-child:last-child:before {
	height: 27px;
}
.float-sub-menu li:after {
	content: '';
	position: absolute;
	left: 0;
	width: 6px;
	height: 6px;
	border: 1px solid #889097;
	top: 11px;
	border-radius: 4px;
	margin-top: -2px;
	z-index: 10;
	background: #242a31;
}
.float-sub-menu li.has-sub > a:before {
	content: '';
	position: absolute;
	left: 2px;
	top: 11px;
	bottom: 11px;
	width: 2px;
}
.float-sub-menu li > a:after {
	content: '';
	position: absolute;
	left: -11px;
	top: 11px;
	width: 11px;
	height: 2px;
}
.float-sub-menu .sub-menu {
	padding: 0 0 0 15px;
	background: none;
}
.float-sub-menu li > a {
	line-height: 18px;
	padding: 3px 20px 3px 15px;
	display:block;
	color: #889097;
	text-decoration: none;
	position: relative;
}
.float-sub-menu li > a:hover,
.float-sub-menu li > a:focus,
.float-sub-menu li.active > a,
.float-sub-menu li.active > a:hover,
.float-sub-menu li.active > a:focus {
	color: #fff;
}
.float-sub-menu li.active:after {
	border-color: @green;
}
.float-sub-menu li > a .caret {
	margin-top: -1px;
	color: #a3a7ac;
}
