/* Login & Register Page with News Feed Setting */

.home.home-with-right-content {
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
}

.home-feature {
  position: fixed;
  left: 0;
  right: 500px;
  top: 0;
  bottom: 0;
  -webkit-transform: translateZ(0);
  overflow: hidden;
}

.home-feature .feature-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.home-feature .feature-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-feature .feature-caption {
  background: rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.7);
  color: #999;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 60px;
  font-size: 14px;
  z-index: 20;
  font-weight: 300;
}

.home-feature .feature-caption .caption-title {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
  font-size: 28px;
}

.home-with-right-content .right-content {
  float: right;
  width: 500px;
  .content-header {
    position: relative;
    top: 0;
    margin: 0;
    left: 0;
    right: 0;
    padding: 80px 60px 0;

    small {
      color: #707478;
      display: block;
      font-size: 14px;
      margin-top: 10px;
      line-height: 20px;
    }
  }
  .content-body {
    width: auto;
    padding: 20px 60px 30px;
  }
}

@media (max-width: 768px) {
  .home-feature {
    display: none;
  }

  .home-with-right-content .right-content {
    float: none;
    width: auto;
    .content-header {
      text-align: center;
      padding: 80px 40px 0;
    }

  }
}