/* Plugin - jVectorMap */

.jvectormap-zoomin, .jvectormap-zoomout {
    width: 16px !important;
    height: 16px !important;
    padding: 0 !important;
    line-height: 16px !important;
    left: 15px !important;
    color: #000 !important;
    background: #fff !important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.25);
}
.jvectormap-label,
.jvectormap-tip {
    padding: 3px 6px !important;
    border: none !important;
    background: rgba(0,0,0,0.6) !important;
}