.pagination {
  > li {
    > a,
    > span {
      color: @black_darker;
    }
  }

  > .active {
    > a, > span, > a:hover, > span:hover, > a:focus, > span:focus {
      background: @main_color !important;
      border-color: @main_color !important;
    }
  }
}

.dataTables_paginate > .pagination {
  margin-top: 0 !important;
}