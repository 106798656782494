/* Variable */

@main_color: #1D67BD;
@dark_main_color: darken(@main_color, 10%);
@light_main_color: lighten(@main_color, 10%);

@bg_body: #e0e4eb;

@blue: @main_color;
@blue_darker: darken(@blue, 10%);
@blue_lighter: lighten(@blue, 10%);

@aqua: #5BC0DE;
@aqua_darker: darken(@aqua, 10%);
@aqua_lighter: lighten(@aqua, 10%);

@green: #28A745;
@green_darker: darken(@green, 10%);
@green_lighter: lighten(@green, 10%);

@red: #DC3545;
@red_darker: darken(@red, 10%);
@red_lighter: lighten(@red, 10%);

@orange: #F0AD4E;
@orange_darker: darken(@orange, 10%);
@orange_lighter: lighten(@orange, 10%);

@black: #2d353c;
@black_darker: #242a30;
@black_darker_2: #1a2229;
@black_lighter: #575d63;

@yellow: #FFEB3B;
@yellow_darker: darken(@yellow, 10%);
@yellow_lighter: lighten(@yellow, 10%);

@silver: #e2e7eb;
@silver_lighter: darken(@silver, 10%);
@silver_darker: lighten(@silver, 10%);

@grey: #b6c2c9;
@grey_darker: darken(@grey, 10%);
@grey_lighter: lighten(@grey, 10%);

@purple: #9C27B0;
@purple_darker: darken(@purple, 10%);
@purple_lighter: lighten(@purple, 10%);

@lime: #90ca4b;
@lime_darker: darken(@lime, 10%);
@lime_lighter: lighten(@lime, 10%);

@white: #ffffff;


// Text Color
@body_text_color: #49494b;
@heading_text_color: #242a30;
@heading_small_text_color: #7c7f83;
@form_label_color: @black_darker;
@header_default_text_color: #585663;
@header_inverse_text_color: #b2b5b8;
@sidebar_text_color: #a8acb1;

@form_border_color: #ccd0d4;


// Font Setting
@body_font_size: 14px;
@body_font_family: 'Open Sans', 'Helvetica Neue',Helvetica,Arial,sans-serif;
@ie8_body_font_family: Arial,sans-serif;