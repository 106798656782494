/* Component - Nav Tabs */

.nav-tabs {
    background: #c1ccd1;
    border-radius: 5px 5px 0 0;
}
.nav-tabs-inverse > .nav-tabs,
.nav-tabs.nav-tabs-inverse {
    background: @black_darker;
}
.nav-tabs .nav-link {
	padding: 10px 15px;
}
.nav-tabs > li > a {
    margin-right: 5px;
    line-height: 20px;
}
.nav-tabs.nav-justified > li > a {
    border-radius: 3px 3px 0 0;
}
.nav-tabs,
.nav-tabs > li > a,
.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus,
.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > .active > a, 
.nav-tabs.nav-justified > .active > a:hover, 
.nav-tabs.nav-justified > .active > a:focus {
    border: none !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active,
.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs.nav-justified > .active > a, 
.nav-tabs.nav-justified > .active > a:hover, 
.nav-tabs.nav-justified > .active > a:focus {
    color: @black_darker;
}
.nav-tabs.nav-tabs-inverse > li.active > a,
.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav-tabs.nav-tabs-inverse > li.active > a:focus,
.nav-tabs.nav-tabs-inverse .nav-link.active,
.nav-tabs.nav-tabs-inverse .nav-link.active:hover,
.nav-tabs.nav-tabs-inverse .nav-link.active:focus,
.nav-tabs.nav-tabs-inverse .nav-item.show .nav-link {
    color: @black_darker;
    background: #fff;
}
.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav-tabs.nav-tabs-inverse > li > a:focus,
.nav-tabs.nav-tabs-inverse .nav-link:hover,
.nav-tabs.nav-tabs-inverse .nav-link:focus {
    color: #fff;
    background: none;
}
.nav-tabs .nav-item,
.nav-tabs > li,
.nav-tabs.nav-justified > li {
    margin-bottom: 0;
}
