/* Plugin - Bootstrap Timepicker */

.bootstrap-timepicker.input-group {
	display: table;
	width: 100%;
}
.bootstrap-timepicker.input-group > .form-control {
	display: table-cell;
	width: 100%;
}
.bootstrap-timepicker.input-group .input-group-addon {
	display: table-cell;
	width: 20px;
}
.bootstrap-timepicker-widget.dropdown-menu {
	padding: 5px !important;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
    border: none !important;
    max-width: 240px;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
	display: none !important;
}
.bootstrap-timepicker .bootstrap-timepicker-widget.dropdown-menu + .form-control {
    border-radius: 4px 0 0 4px;
}
.bootstrap-timepicker-widget .form-control {
	width: 45px;
}
.bootstrap-timepicker-widget table td {
	padding: 5px !important;
}
.bootstrap-timepicker-widget table td a {
	padding: 5px 0 !important;
	margin: -5px 0 !important;
}
.bootstrap-timepicker-widget table td a:hover,
.bootstrap-timepicker-widget table td a:focus {
    background: @blue !important;
    border: none !important;
    color: #fff;
}
.bootstrap-timepicker-widget table td input {
    border-radius: 4px !important;
}