/* Flat Black - Body Setting */

body.flat-black {
    background: #E7E7E7;
}


/* Flat Black -  Navbar Inverse Setting */

.flat-black .navbar.navbar-inverse {
    background: #212121;
}
.flat-black .navbar.navbar-inverse .navbar-form .form-control {
    background: #4a4a4a;
    border-color: #4a4a4a;
}


/* Flat Black - Sidebar Setting */

.flat-black .sidebar,
.flat-black .sidebar-bg {
    background: #3A3A3A;
}
.flat-black .sidebar .nav > li > a {
    color: #b2b2b2;
}
.flat-black .sidebar.sidebar-grid .nav > li > a {
    border-bottom: 1px solid #474747;
    border-top: 1px solid #474747;
}
.sidebar .nav > li.nav-profile > a,
.flat-black .sidebar .nav > li > a:focus, 
.flat-black .sidebar .nav > li > a:hover,
.flat-black .sidebar .nav > li.active > a,
.flat-black .sidebar .sub-menu > li > a:hover,
.flat-black .sidebar .sub-menu > li > a:focus,
.flat-black .sidebar .active .sub-menu > li.active > a {
    color: #fff;
}
.flat-black .sidebar .sub-menu > li > a,
.flat-black .sidebar .sub-menu > li > a:before {
    color: #999;
}
.flat-black .page-sidebar-minified .sidebar .nav > li.has-sub:hover > a, 
.flat-black .page-sidebar-minified .sidebar .nav > li.has-sub:focus > a {
    background: #323232;
}
.flat-black .sidebar .nav > li.active > a, 
.flat-black .sidebar .nav > li.active > a:focus, 
.flat-black .sidebar .nav > li.active > a:hover,
.flat-black .sidebar .sub-menu > li:before, 
.flat-black .sidebar .sub-menu > li > a:after, 
.flat-black .sidebar .sub-menu > li.has-sub > a:before,
.flat-black .page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu,
.flat-black .sidebar .nav > li.nav-profile {
    background: #2A2A2A;
}
.flat-black .page-sidebar-minified .sidebar .sub-menu > li:before, 
.flat-black .page-sidebar-minified .sidebar .sub-menu > li > a:after,
.flat-black .page-sidebar-minified .sidebar .sub-menu > li > a:after {
	background: #3e3e3e;
}
.flat-black .sidebar .nav > li.nav-profile .cover.with-shadow:before {
	background: rgba(42, 42, 42, 0.75);
}


/* Flat Black - Page Footer Setting */

.flat-black .footer {
    border-color: #D7D7D7;
}


/* Flat Black - List Group item Setting */

.flat-black .list-group-item.list-group-item-inverse {
    background: #3A3A3A;
    border-color: #242424;
}
.flat-black .list-group-item.list-group-item-inverse:hover,
.flat-black .list-group-item.list-group-item-inverse:focus {
    background: #323232;
}


/* Flat Black - Nav Pills Setting */

.flat-black .nav-pills > li.active > a, 
.flat-black .nav-pills > li.active > a:focus,
.flat-black .nav-pills > li.active > a:hover {
    background: #2A2A2A;
}


/* Flat Black - Badge Setting */

.flat-black .badge.badge-inverse, 
.flat-black .label.label-inverse {
    background: #3A3A3A;
}
.flat-black .badge.badge-default, 
.flat-black .label.label-default {
    background: #C3C3C3;
}


/* Flat Black - Tabs Setting */

.flat-black .nav-tabs {
    background: #d2d2d2;
}
.flat-black .nav-tabs-inverse > .nav-tabs,
.flat-black .nav-tabs.nav-tabs-inverse {
    background: #2A2A2A;
}


/* Flat Black - Panel Setting */

.flat-black .panel-with-tabs .panel-heading {
    background: #d2d2d2;
}
.flat-black .panel-inverse > .panel-heading {
    background: #2A2A2A;
}


/* Flat Black - Table Setting */

.flat-black .table > tbody > tr > td, 
.flat-black .table > tbody > tr > th, 
.flat-black .table > tfoot > tr > td, 
.flat-black .table > tfoot > tr > th, 
.flat-black .table > thead > tr > td, 
.flat-black .table > thead > tr > th {
    border-color: #E0E0E0;
}
.flat-black .table > thead > tr > th {
    border-color: #E0E0E0 !important;
}
.flat-black .dataTables_filter input {
    background: #f7f7f7;
    border-color: #f7f7f7;
}
.flat-black .dataTables_filter input:focus {
    border-color: #D7D7D7;
}
.flat-black .table-striped > tbody > tr:nth-child(odd) > td, 
.flat-black .table-striped > tbody > tr:nth-child(odd) > th {
    background: #f7f7f7;
}


/* Flat Black - Button Inverse Setting */

.flat-black .btn.btn-inverse {
    background: #3A3A3A;
    border-color: #242424;
}
.flat-black .btn-inverse:hover, 
.flat-black .btn-inverse:focus, 
.flat-black .btn-inverse:active, 
.flat-black .btn-inverse.active, 
.flat-black .open .dropdown-toggle.btn-inverse {
    background: #2a2a2a;
}


/* Flat Black - Button Default Setting */

.flat-black .btn.btn-default {
    background: #c8c8c8;
    border-color: #c8c8c8;
}
.flat-black .btn.btn-default:hover,
.flat-black .btn.btn-default:focus,
.flat-black .btn.btn-default:active,
.flat-black .btn.btn-default.active, 
.flat-black .open .dropdown-toggle.btn.btn-default {
    background: #a3a3a3;
    border-color: #a3a3a3;
}


/* Flat Black - Button White Setting */

.flat-black .btn.btn-white,
.flat-black .btn-group .btn.btn-white:not(.active) + .btn.btn-white, 
.flat-black .input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
    border-color: #d2d2d2;
}
.flat-black .btn.btn-white:hover,
.flat-black .btn.btn-white:focus,
.flat-black .btn.btn-white:active, 
.flat-black .btn.btn-white.active, 
.flat-black .open .dropdown-toggle.btn.btn-white {
    background: #e0e0e0;
    border-color: #d2d2d2;
}


/* Flat Black - Pagination Setting */

.flat-black .pager li > a, 
.flat-black .pager li > span, 
.flat-black .pagination > li > a {
    border-color: #d2d2d2;
}
.flat-black .pagination > .active > a, 
.flat-black .pagination > .active > a:focus, 
.flat-black .pagination > .active > a:hover, 
.flat-black .pagination > .active > span, 
.flat-black .pagination > .active > span:focus, 
.flat-black .pagination > .active > span:hover {
    background: #323232 !important;
    border-color: #323232 !important;
}
.flat-black .pager li > a:focus, 
.flat-black .pager li > a:hover, 
.flat-black .pager li > span:focus, 
.flat-black .pager li > span:hover, 
.flat-black .pagination > li > a:focus, 
.flat-black .pagination > li > a:hover {
    background: #e0e0e0;
}


/* Flat Black -  Progress Bar Setting */

.flat-black .progress {
    background: #eaeaea;
}
.flat-black .widget-stats .stats-progress {
    background: rgba(0,0,0,.2);
}


/* Flat Black - Vector Map Setting */

.flat-black .jvectormap-container {
    background: #2d2d2d !important;
}


/* Flat Black - Helper Classes Setting */

.flat-black .bg-black {
    background: #3A3A3A !important;
}
.flat-black .bg-silver {
    background: #f7f7f7 !important;
}
.flat-black .bg-black-darker {
    background: #2d2d2d !important;
}


/* Flat Black - Bootstrap Wizard Setting */

.flat-black .bwizard-steps li.active, 
.flat-black .bwizard-steps li.active:hover, 
.flat-black .bwizard-steps li.active:focus {
    background: @green !important;
}
.flat-black .bwizard-steps li:hover, 
.flat-black .bwizard-steps li:focus {
    background: #DFDFDF !important;
}
.flat-black .bwizard-steps li {
    background: #f7f7f7 !important;
}


/* Flat Black - Timeline */

.flat-black .timeline-footer {
    background: #F2F2F2;
}


/* Flat Black - Invoice */

.flat-black .invoice-header,
.flat-black .invoice-price {
    background: #F5F5F5;
}
.flat-black .invoice-price .invoice-price-right {
    background: #3a3a3a;
}


/* Flat Black - Error Page */

.flat-black .error-content {
    background: #3a3a3a;
}
.flat-black .error-code {
    color: #3a3a3a;
}
.flat-black .error-desc {
    color: #888;
}