/* Plugin - X-Editable */

.editable-date {
	width: 300px;
}
.editable-input {
	font-size: 12px;
}
.editable-error-block {
	font-size: 12px;
}
.editable-date .datetimepicker {
	width: auto !important;
	color: #2e353c !important;
}
.editable-input .date .form-control,
.editable-input .datetimepicker.datetimepicker-inline {
	width: 240px;
}
.editable-input .input-sm,
.editable-input select.form-control:not([size]):not([multiple]).input-sm {
	height: 30px;
}
.editable-input .date .add-on {
	display: none;
}
.editable-input .datetimepicker.datetimepicker-inline table > thead > tr > th, 
.editable-input .datetimepicker.datetimepicker-inline table thead th,
.editable-input .datetimepicker.datetimepicker-inline table tfoot th,
.editable-input .datetimepicker.datetimepicker-inline table tbody td {
	border: none !important;
	vertical-align: middle;
}
.editable-input .tt-dropdown-menu {
	border: none !important;
	font-size: 12px;
	box-shadow: 0 10px 30px 0px rgba(0,0,0,.2) !important;
}
.editable-input .tt-suggestion.tt-is-under-cursor {
	background: @blue !important;
}
.editable-input .twitter-typeahead .tt-hint {
	padding: 5px 9px !important;
}
.editable-checklist {
	font-size: 12px;
	min-width: 200px;
}
.editableform textarea.form-control {
	min-width: 240px;
}
.editable-checklist label {
	display: block;
}
.editable-checklist label input[type="checkbox"].form-control {
	box-shadow: none !important;
	height: 20px !important;
}
.editable-checklist label input[type="checkbox"] + span {
	margin-left: 5px;
}
.editable-input .select2-container .select2-choice > .select2-chosen {
	color: #2e353c;
}
.editable-buttons .glyphicon-ok:before {
	content: '\f00c';
}
.editable-buttons .glyphicon-remove:before {
	content: '\f00d';
}