mark, .mark {
  color: @main_color;
  background-color: transparent;
  padding: 0;
}

.doc-title-col {
  .sub-detail {
    mark, .mark {
      font-weight: bold;
    }
  }
}