/* Component - Tab Content */

.tab-content {
    padding: 15px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 3px;
}
.nav-tabs + .tab-content {
    border-radius: 0 0 3px 3px;
}