.panel-footer {
  &.affix {
    position: fixed;
    bottom: 0;
    width: calc(~"100% - 260px");
    -webkit-box-shadow: 0 -4px 8px -4px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 -4px 8px -4px rgba(0, 0, 0, .1);
    box-shadow: 0 -4px 8px -4px rgba(0, 0, 0, .1);
    z-index: 1000;
  }
  &.affix-bottom {
    position: relative;
  }
}

@media (max-width: 767px) {
  .panel-footer {
    &.affix {
      width: calc(~"100% - 40px");
    }
  }
}