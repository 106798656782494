.file-icon-col {
  width: 44px;
}

.file-name-col {
  padding-left: 0 !important;
  p.name {
    margin-bottom: 0;
  }
}

.file-size-col {
  width: 100px;
  p.size {
    margin-bottom: 0;
  }
  .progress {
    height: 4px;
    margin-bottom: 0;
  }
}

.file-op-col {
  width: 60px;
}

.fileupload-progress {
  .progress {

    margin-top: -15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

  }

}

.fade.in {
  opacity: 1;
}

//.template-upload, .template-download {
//  &:first-of-type {
//    td {
//      border-top: none;
//    }
//  }
//}