.date-container input[readonly] {
  background: #fff;
  text-align: left;
  cursor: pointer;
  opacity: 1;
}

.datepicker {
  .table-condensed > tbody > tr > td,
  .table-condensed > tbody > tr > th,
  .table-condensed > tfoot > tr > td,
  .table-condensed > tfoot > tr > th,
  .table-condensed > thead > tr > td,
  .table-condensed > thead > tr > th {
    padding: 8px;
  }
}