.dataTables_filter {
  label {
    &:before {
      font-family: "Font Awesome\ 5 Free";
      font-size: 16px;
      color: #aaa;
      content: "\f002";
      position: absolute;
      margin-top: 5px;
      padding-left: 8px;
    }
  }

  input[type=search] {
    padding-left: 30px;
  }
}

table.dataTable thead {
  .sorting_asc, .sorting_desc {
    &:after {
      opacity: 1;
      color: @main_color;
    }
  }
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  position: relative;
  bottom: 0;
  right: 0;
  left: 8px;
  display: inline-block;
  font-family: 'Font Awesome\ 5 Free';
}

table.dataTable thead .sorting:after {
  opacity: 0.2;
  content: "\f0ec";
  /* sort */
}
table.dataTable thead .sorting_asc:after {
  content: "\f160";
  /* sort-by-attributes */
}
table.dataTable thead .sorting_desc:after {
  content: "\f161";
  /* sort-by-attributes-alt */
}
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  color: #eee;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: #767676;
}

table.dataTable tbody>tr.selected,
table.dataTable tbody>tr.selected td,
table.dataTable tbody>tr>.selected {
  background: lighten(@light_main_color, 44%) !important;
}

table.dataTable thead>tr {

  &.selected>.select-checkbox  {
    &:after {
      content: '\f14a';
      color: @main_color;
    }
  }

  &.indeterminate>.select-checkbox {
    &:after {
      content: '\f146';
      color: @main_color;
    }
  }

}

table.dataTable.processing {
  &>tbody {
    opacity: .4;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    &>tr>td {
      border-color: transparent;
    }
  }
}

#table_search_result {
  .doc-title-col {
    &:hover {
      cursor: pointer;
    }
  }
}

.doc-table {
  .badge, .label {
    font-weight: normal;
  }
}

#table_search_result_filter.dataTables_filter label:before {
  //change search icon to filter icon
  content: "\f0b0";
}

@media (min-width: 768px) {

  .dataTables_filter {
    display: inline-block;
    label {
      display: inline-block;
      width: auto;
    }
  }
  
  .table-function-area {
    display: inline-block;
    margin-left: 8px;
    margin-top: -1px;
  }
}