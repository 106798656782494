/* Plugin - Intro Js Setting */

.introjs-hint {
    width: 10px !important;
    height: 10px !important;
}
.introjs-hint-dot {
    left: -20px !important;
    top: -20px !important;
}
.introjs-helperNumberLayer {
    font-size: 12px;
    background: @red !important;
    font-family: inherit !important;
    border: none !important;
    text-shadow: none !important;
    padding: 0 !important;
    text-align: center !important;
}
