/* Widget - Unlimited Nav Tabs */

.tab-overflow {
    position: relative;
    overflow: hidden;
    border-radius: 2px 2px 0 0;
}
.tab-overflow .nav.nav-tabs {
    white-space: nowrap;
    overflow: hidden;
    display: table;
    margin: 0;
    border-radius: 2px 2px 0 0;
}
.tab-overflow .nav.nav-tabs > li,
.tab-overflow.nav.nav-tabs > li {
    display: table-cell;
    float: none;
}
.tab-overflow .nav.nav-tabs > li > a {
    margin-left: -1px;
}
.tab-overflow .next-button,
.tab-overflow .prev-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    display: none !important;
    opacity: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: all .2s linear;
}
.tab-overflow .prev-button {
    left: 0;
    right: auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .next-button,
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .prev-button {
    box-shadow: 0 0 15px #000;
}
.tab-overflow .nav.nav-tabs.nav-tabs-inverse .prev-button {
    box-shadow: 0 0 15px #000;
}
.tab-overflow .next-button a,
.tab-overflow .prev-button a {
    margin: 0;
    background: #c1ccd1;
}
.flat-black .tab-overflow .next-button a,
.flat-black .tab-overflow .prev-button a {
    background: #d2d2d2;
}
.tab-overflow .prev-button > a {
    border-radius: 3px 0 3px;
}
.tab-overflow .nav-tabs-inverse .next-button > a,
.tab-overflow .nav-tabs-inverse .prev-button > a,
.nav.nav-tabs.nav-tabs-inverse > li > a,
.nav.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav.nav-tabs.nav-tabs-inverse > li > a:focus {
    background: @black_darker;
}
.flat-black .tab-overflow .nav-tabs-inverse .next-button > a,
.flat-black .tab-overflow .nav-tabs-inverse .prev-button > a,
.flat-black .nav.nav-tabs.nav-tabs-inverse > li > a,
.flat-black .nav.nav-tabs.nav-tabs-inverse > li > a:hover,
.flat-black .nav.nav-tabs.nav-tabs-inverse > li > a:focus {
    background: #2A2A2A;
}
.nav.nav-tabs.nav-tabs-inverse > li.active > a,
.nav.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav.nav-tabs.nav-tabs-inverse > li.active > a:focus {
    background: #fff;
    color: @black_darker;
}
.tab-overflow.overflow-right .next-button,
.tab-overflow.overflow-left .prev-button {
    display: block !important;
    opacity: 1;
    background: none;
}
.tab-overflow .nav-tabs > li.prev-button + li.active > a {
    border-radius: 0 3px 0 0;
}