/* Plugin - DateTimepicker (Added in V1.2) */

.datetimepicker table {
    width: 100%;
}
.datetimepicker th, 
.datetimepicker td {
    padding: 0px 5px !important;
    width: 30px !important;
    height: 30px !important;
}
.datetimepicker table tr td.active,
.datetimepicker table tr span.active {
    background-image: none !important;
    background: @blue!important;
    color: #fff !important;
    text-shadow: none !important;
}