/* Component - Dropdown Menu */

.dropdown-menu {
    border: none;
    box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.25);
    font-size: 12px;
}
.dropdown-menu > li > a,
.dropdown-item {
	display: block;
    padding: 5px 15px;
	clear: both;
	font-weight: 400;
	line-height: 1.42857143;
	color: #333;
	white-space: nowrap;
	text-decoration: none;
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-menu > li > a:hover, 
.dropdown-menu > li > a:focus {
    background: #f2f3f4;
}
.dropdown-menu > .active > a, 
.dropdown-menu > .active > a:hover, 
.dropdown-menu > .active > a:focus {
    background: @blue;
    color: #fff;
}
.dropdown-menu .divider,
.dropdown-divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}
.dropdown-menu .divider {
    border-color: #e5e5e5;
}
.dropdown-menu.media-list {
    max-width: 360px;
    padding: 0;
}
.dropdown-menu.media-list p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
    line-height: 16px;
    max-width: 270px;
    color: #585c61;
}
.dropdown-menu.media-list .dropdown-header {
    padding: 10px 15px !important;
    background: #f2f3f4;
    font-size: 10px;
    color: #585c61;
}
.dropdown-menu.media-list .dropdown-header + .media {
    margin-top: -10px;
}
.dropdown-menu.media-list > .media {
    margin-top: 0;
    border-top: 1px solid #f2f3f4;
    border-bottom: 1px solid #f2f3f4;
    margin-bottom: -1px;
}
.dropdown-menu.media-list > .media > a {
    display: block;
    padding: 10px 15px !important;
    clear: both;
    white-space: nowrap;
}
.dropdown-menu.media-list > .media > a:hover,
.dropdown-menu.media-list > .media > a:focus {
	background: #f9f9f9;
}
.dropdown-menu.media-list > .media .media-left {
    padding-right: 10px;
}
.dropdown-menu.media-list > .media .media-right {
    padding-left: 10px;
}
.dropdown-menu.media-list > .media .media-object {
    height: 48px;
    width: 48px;
    line-height: 48px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
}
.dropdown-menu.media-list > .media .media-object-icon {
	position: absolute;
    margin-left: 30px;
    border: 2px solid #fff;
    border-radius: 40px;
    background: #fff;
    margin-top: -11px;
    display: block;
    width: 22px;
    height: 22px;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
}
.dropdown-footer {
    padding: 10px 15px;
}
.dropdown-menu > li.dropdown-footer > a {
    padding: 0 !important;
    display: inline !important;
}
.dropdown-menu > li.dropdown-footer > a:hover,
.dropdown-menu > li.dropdown-footer > a:focus {
    background: none !important;
    text-decoration: underline !important;
}
.dropdown-toggle:after {
	vertical-align: 1px;
	border-width: 4px;
}
.dropdown-toggle.no-caret:after {
	display: none;
}
