/* Option - Page with Right Sidebar */

.page-with-right-sidebar .content {
    margin-left: 0;
    margin-right: 220px;
}
.page-with-right-sidebar .footer {
    margin-left: 30px;
    margin-right: 250px;
}
