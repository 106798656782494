/* Theme - Blue */

.header .navbar-logo, .login .login-header .brand .logo, .header .navbar-nav > li > a .label, .label.label-theme, .btn.btn-scroll-to-top {
	background:@blue;
}
.header.navbar-inverse .navbar-brand {
	color:#ffffff !important;
}
.text-theme, .sidebar .nav > li.active > a i {
	color:@blue;
}
.sidebar .sub-menu > li.active:after {
	border-color:@blue;
}
.gradient-enabled .sidebar .nav > li.active > a {
	background:#67b0f0;
	background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY3YjBmMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzNDhmZTIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background:-moz-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:-webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(103,176,240,1)),color-stop(100%,rgba(52,143,226,1)));
	background:-webkit-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:-o-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:-ms-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:linear-gradient(to bottom,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#67b0f0',endColorstr='@blue',GradientType=0);
	z-index:10;
	i, .caret {
		color:rgba(0,0,0,0.5);
	}
}
.gradient-enabled .sidebar .nav > li.active > a:focus, .gradient-enabled .sidebar .nav > li.active > a:hover, .gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, .gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, .gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a, .gradient-enabled .label.label-theme, .gradient-enabled .btn.btn-scroll-to-top, .gradient-enabled .top-menu .nav > li.active > a, .gradient-enabled .top-menu .nav > li.active > a:focus, .gradient-enabled .top-menu .nav > li.active > a:hover {
	background:#67b0f0;
	background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY3YjBmMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzNDhmZTIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background:-moz-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:-webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(103,176,240,1)),color-stop(100%,rgba(52,143,226,1)));
	background:-webkit-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:-o-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:-ms-linear-gradient(top,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	background:linear-gradient(to bottom,rgba(103,176,240,1) 0%,rgba(52,143,226,1) 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#67b0f0',endColorstr='@blue',GradientType=0);
}
.pace-progress {
	background:@blue;
}
.pace .pace-activity {
	border-top-color:@blue;
	border-left-color:@blue;
}
.page-with-light-sidebar .sidebar .nav > li.active > a {
	.badge, .label.label-theme {
		background:@blue;
	}
	> i {
		color:@blue;
	}
}
.top-menu .nav > li.active {
	> a i, > a:focus i, > a:hover i {
		color:@blue;
	}
}