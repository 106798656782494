/* Pages - Login */

.login {
    margin: -168px 0 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
}
.login label {
    color: #707478;
}
.login .login-header {
    position: absolute;
    top: -80px;
    left: 50%;
    right: 0;
    width: 450px;
    padding: 0 40px;
    margin-left: -225px;
    font-weight: 300;
}
.login .login-header .brand {
    padding: 0;
    font-size: 28px;
    color: @black;
}
.login .login-header .brand .logo {
    border: 14px solid transparent;
    border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.2) rgba(0,0,0,0.3);
    width: 28px;
    height: 28px;
    position: relative;
    font-size: 0;
    margin-right: 10px;
    border-radius: 6px;
    top: -11px;
}
.login .login-header .brand small {
    font-size: 14px;
    display: block;
    color: #707478;
}
.login .login-header .icon {
    position: absolute;
    right: 40px;
    top: -2px;
    opacity: 0.1;
}
.login .login-header .icon i {
    font-size: 70px;
}
.login .login-content {
    padding: 30px 40px;
    color: #999;
    width: 450px;
    margin: 0 auto;
}