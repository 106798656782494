/* Pages - Login V3 */

.login.login-with-news-feed,
.register.register-with-news-feed {
    width: 100%;
    margin: 0;
    padding: 0;
    top: 0;
}
.login.login-with-news-feed .news-feed,
.register.register-with-news-feed .news-feed {
    position: fixed;
    left: 0;
    right: 500px;
    top: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}
.login.login-with-news-feed .news-image,
.register.register-with-news-feed .news-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.login.login-with-news-feed .news-image img,
.register.register-with-news-feed .news-image img {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    max-height: 100%;
    min-width: 100%;
    top: -1960px;
    bottom: -1960px;
    left: -1960px;
    right: -1960px;
    margin: auto;
}
.login.login-with-news-feed .news-caption,
.register.register-with-news-feed .news-caption {
    color: rgba(255, 255, 255, 0.75);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 60px 60px 30px;
    font-size: 18px;
    z-index: 20;
    font-weight: 300;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}
.login.login-with-news-feed .news-caption .caption-title,
.register.register-with-news-feed .news-caption .caption-title {
    color: #fff;
    font-weight: 300;
    font-size: 36px;
}
.login.login-with-news-feed .right-content,
.register.register-with-news-feed .right-content {
    float: right;
    width: 500px;
}
.login.login-with-news-feed .login-content,
.register.register-with-news-feed .register-content {
    width: auto;
    padding: 30px 60px;
}
.register.register-with-news-feed .register-content {
    padding: 20px 60px 30px;
}
.login.login-with-news-feed .login-header,
.register.register-with-news-feed .register-header {
    position: relative;
    top: 0;
    margin: 0;
    left: 0;
    right: 0;
    padding: 100px 60px 0;
}
.register.register-with-news-feed .register-header {
    padding: 60px 60px 0;
    font-size: 32px;
}
.register.register-with-news-feed .register-header small {
    color: #707478;
    display: block;
    font-size: 14px;
    margin-top: 10px;
    line-height: 20px;
}
.login.login-with-news-feed .login-header .brand {
    color: @black_darker;
}
.login.login-with-news-feed .login-header .brand small {
    color: #707478;
}
.login.login-with-news-feed .login-header .icon {
    top: 98px;
    right: 10px;
}