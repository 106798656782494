/* Component - CSS Radio Button */

.radio.radio-css {
	position: relative;
	line-height: 16px;
	padding-top: 7px;
}
.radio.radio-css.radio-inline {
	padding-left: 0;
	display: inline-block;
}
.radio.radio-css.radio-inline + .radio.radio-css.radio-inline {
	margin-left: 15px;
}
.radio.radio-css label {
	padding-left: 24px;
	margin: 0;
}
.radio.radio-css label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 7px;
	width: 16px;
	height: 16px;
	border-radius: 16px;
	background: #dee2e6;
}
.has-success .radio.radio-css label:before,
.has-success.radio.radio-css label:before,
.is-valid .radio.radio-css label:before,
.is-valid.radio.radio-css label:before {
	background: #b3e6e6;
}
.has-success .radio.radio-css label,
.has-success.radio.radio-css label,
.is-valid .radio.radio-css label,
.is-valid.radio.radio-css label {
	color: @green_darker;
}
.has-error .radio.radio-css label:before,
.has-error.radio.radio-css label:before,
.is-invalid .radio.radio-css label:before,
.is-invalid.radio.radio-css label:before {
	background: #ffcdcc;
}
.has-error .radio.radio-css label,
.has-error.radio.radio-css label,
.is-invalid .radio.radio-css label,
.is-invalid.radio.radio-css label {
	color: @red_darker;
}
.has-warning .radio.radio-css label:before,
.has-warning.radio.radio-css label:before {
	background: #fce1ba;
}
.radio.radio-css input {
	display: none;
}
.radio.radio-css input:checked + label:after {
	content: '';
	position: absolute;
	top: 12px;
	left: 5px;
	width: 6px;
	height: 6px;
	border-radius: 6px;
	background: #fff;
}
.radio.radio-css input:checked + label:before {
	background: @blue;
	border-color: @blue;
}
.radio.radio-css.radio-success input:checked + label:before {
	background: @green;
	border-color: @green;
}
.radio.radio-css.radio-info input:checked + label:before {
	background: @aqua;
	border-color: @aqua;
}
.radio.radio-css.radio-danger input:checked + label:before {
	background: @red;
	border-color: @red;
}
.radio.radio-css.radio-warning input:checked + label:before {
	background: @orange;
	border-color: @orange;
}
.radio.radio-css.radio-inverse input:checked + label:before {
	background: @black;
	border-color: @black;
}