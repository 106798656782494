/* Option - Page without Sidebar */

.page-without-sidebar .content {
    margin-left: 0;
}
.page-with-right-sidebar .sidebar,
.page-with-right-sidebar .sidebar-bg {
    left: auto;
    right: 0;
}
