.form-control:focus {
  border-color: @main_color;
  .box-shadow(none);
}

textarea.form-control {
  width: 100% !important;
}

.form-control-plaintext {
  height: auto;
}

.input-group-addon {
  color: @body_text_color;
  background: @silver;
  border: 1px solid @form_border_color;

  &:not(:first-child) {
    border-left: none;
  }

  &:not(:last-child) {
    border-right: none;
  }

}

.row.form-group {
  margin-bottom: 1rem;
}

.col-form-label,
.row.form-group > .col-form-label {
  padding-top: inherit;
  padding-bottom: inherit;
}

.form-bordered {
  .col-form-label,
  .row.form-group > .col-form-label {
    padding: 22px 15px 15px;
  }

}

.input-group {
  .input-group-append {
    .form-control:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;
    }
  }


}

//form {
//  .row {
//    margin: 0;
//  }
//}

.page-sidebar-minified {
  form {
    .panel-footer {
      &.affix {
        width: calc(~"100% - 100px");
      }
    }
  }
}

@media (max-width: 767px) {

}