.navbar-brand {
  margin-right: 0;
  padding: 8px 16px;
  img {
    object-fit: contain;
  }
}

.header {
  height: 54px;
  &.navbar-inverse {
    .navbar-brand {
      background: none;
    }
  }
}

.img-flag {
  width: 18px;
  height: 13.5px;
}

.nav > li > a {
  color: #676767;
}

.nav-tabs {
  background: #e7eaef;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius:4px 4px 0 0;
  border-radius: 4px 4px 0 0;

  li.active {
    .hidden-xs {
      display: inline-block !important;
    }
  }
}

.navbar-nav > li > a .label {
  position: absolute;
  top: 7px;
  right: 3px;
  display: block;
  background: @red_darker;
  line-height: 12px;
  font-weight: 300;
  padding: .3em .6em .3em;
  .border-radius(20px);
}

.nav.nav-tabs.nav-tabs-inverse>li>a {
  color: @header_inverse_text_color;
}

.ma-mode {

  .ma-indicator {
    background: @red;
    color: @white;
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1020;
  }

  .sidebar, .footer {
    padding-bottom: 70px;
  }

  .btn-scroll-to-top {
    bottom: 90px;
  }
}

@media (min-width: 768px) {

  .header .navbar-header {
    margin-left: -15px;
  }

}
